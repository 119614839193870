import React, { useCallback } from "react";
import { useNavigate } from "react-router-dom";
import {
  AppBar,
  Toolbar,
  IconButton,
  Typography,
  Button,
  Divider,
} from "@mui/material";
import { useSelector, useDispatch } from "react-redux";
import ArrowBackIosRoundedIcon from "@mui/icons-material/ArrowBackIosRounded";
import DeleteForeverRoundedIcon from "@mui/icons-material/DeleteForeverRounded";
import ProductionQuantityLimitsRoundedIcon from "@mui/icons-material/ProductionQuantityLimitsRounded";
import styles from "./Cart.module.css";
import CartItem from "./CartItem";
import {
  selectAllCartProducts,
  getCartPrice,
  clearCart,
  getCartCurrencyCode,
} from "../../redux/cartSlice";
import getProductAmountLabel from "../../utils/getProductAmountLabel";

function Cart() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const products = useSelector(selectAllCartProducts);
  const price = useSelector(getCartPrice);
  const currencyCode = useSelector(getCartCurrencyCode);
  const itemsLabel = getProductAmountLabel(products.length);

  const navigateToProducts = () => {
    navigate("/products");
  };

  const navigateToCheckout = () => {
    navigate("/checkout");
  };

  const handleClearCart = useCallback(() => {
    dispatch(clearCart());
  }, [dispatch]);

  return (
    <div className={styles.cartContainer}>
      <AppBar
        position="fixed"
        sx={{
          backgroundColor: "transparent",
          // backgroundColor: 'var(--tg-theme-section-bg-color)',
          // boxShadow: 'none',
          boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.2)",
          height: "56px",
          backdropFilter: "blur(42px)",
          WebkitBackdropFilter: "blur(42px)",
        }}
      >
        <Toolbar sx={{ height: "56px" }}>
          <IconButton onClick={navigateToProducts}>
            <ArrowBackIosRoundedIcon className={styles.icon} />
          </IconButton>
        </Toolbar>
      </AppBar>
      {products.length ? (
        <>
          <div className={styles.cart}>
            <div className={styles.cartHeader}>
              <Typography
                variant="body2"
                sx={{
                  fontSize: "20px",
                  marginLeft: "16px",
                  textAlign: "left",
                  color: "var(--tg-theme-text-color)",
                }}
              >
                Cart
              </Typography>
              <IconButton>
                <DeleteForeverRoundedIcon
                  className={styles.icon}
                  onClick={handleClearCart}
                />
              </IconButton>
            </div>
            <div className={styles.cartItems}>
              {products.map((product, index) => (
                <div>
                  <CartItem key={product.id} product={product} />
                  {index + 1 < products.length && <Divider />}
                </div>
              ))}
            </div>
          </div>
          <div className={styles.totalContainer}>
            <div>
              <Typography
                variant="body2"
                sx={{
                  fontSize: "20px",
                  marginLeft: "16px",
                  textAlign: "left",
                  color: "var(--tg-theme-text-color)",
                }}
              >
                Total
              </Typography>
              <Typography
                variant="body2"
                sx={{
                  marginLeft: "16px",
                  textAlign: "left",
                  color: "var(--tg-theme-text-color)",
                }}
              >
                {itemsLabel}
              </Typography>
            </div>
            <div>
              <Typography
                variant="h5"
                sx={{
                  marginRight: "16px",
                  color: "var(--tg-theme-text-color)",
                }}
              >
                {`${price} ${currencyCode}`}
              </Typography>
            </div>
          </div>
          <div className={styles.footer}>
            <Button
              variant="contained"
              disableElevation
              onClick={navigateToCheckout}
              sx={{
                width: "95%",
                height: "50px",
                backgroundColor: "var(--tg-theme-button-color)",
                color: "var(--tg-theme-button-text-color)",
                borderRadius: "25px",
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
              }}
            >
              <Typography variant="body2" sx={{ textTransform: "lowercase" }}>
                {itemsLabel}
              </Typography>
              <Typography>Confirm</Typography>
              <Typography variant="body2">
                {`${price} ${currencyCode}`}
              </Typography>
            </Button>
          </div>
        </>
      ) : (
        <div
          className={styles.emptyContainer}
          onClick={navigateToProducts}
          onKeyDown={(event) => {
            if (event.key === "Enter" || event.key === " ") {
              navigateToProducts();
            }
          }}
          role="button"
          tabIndex={0}
        >
          <ProductionQuantityLimitsRoundedIcon
            sx={{
              width: "40%",
              height: "40%",
              color: "var(--tg-theme-hint-color)",
            }}
          />
          <Typography
            variant="body2"
            sx={{ color: "var(--tg-theme-hint-color)", fontSize: "18px" }}
          >
            Your cart is empty
          </Typography>
          <Typography
            variant="body2"
            sx={{ color: "var(--tg-theme-hint-color)", fontSize: "14px" }}
          >
            Looks like you have not added anything to your cart. Go ahead & find
            interesting products.
          </Typography>
        </div>
      )}
    </div>
  );
}

export default Cart;
