import { createSelector } from "@reduxjs/toolkit";
import { getAuthError } from "./authSlice";
import { getProductError } from "./productSlice";
import { getShippingInfoError } from "./shippingInfoSlice";
import { getCountriesError } from "./countriesSlice";
import { getOrderItemStatusesError } from "./orderItemStatusesSlice";
import { getOrderError } from "./orderSlice";
import { getOrdersError } from "./ordersSlice";
import { getPaymentMethodsError } from "./paymentMethodsSlice";
import { getProductsError } from "./productsSlice";

// Combine all errors from different slices
const getAllErrors = createSelector(
  [getAuthError, getProductError, getProductsError,
    getShippingInfoError, getCountriesError, getOrderItemStatusesError,
    getOrderError, getOrdersError, getPaymentMethodsError],
  (authError, productError, productsError, shippingError, countriesError, orderItemStatusesError, orderError, ordersError, paymentMethodsError) => authError
    || productError || shippingError || countriesError
    || orderItemStatusesError || orderError || ordersError
    || paymentMethodsError,
);

export default getAllErrors;
