import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import {
  List,
  ListItem,
  ListItemText,
  ListItemIcon,
  Radio,
  Button,
} from '@mui/material';
import { useSelector, useDispatch } from 'react-redux';
import {
  fetchPaymentMethods,
  getPaymentMethods,
  getPaymentMethodsStatus,
  setSelectedMethod,
  selectedPaymentMethod,
} from '../../redux/paymentMethodsSlice';
import styles from './PaymentMethod.module.css';

function PaymentMethod({ onSave }) {
  const paymentMethods = useSelector(getPaymentMethods);
  const paymentMethodsStatus = useSelector(getPaymentMethodsStatus);
  const selectedMethod = useSelector(selectedPaymentMethod);
  const dispatch = useDispatch();

  useEffect(() => {
    if (paymentMethodsStatus === 'idle') {
      dispatch(fetchPaymentMethods());
    }
  }, [paymentMethodsStatus]);

  const handleMethodSelect = (method) => {
    // Dispatch the setSelectedMethod action with the selected method
    dispatch(setSelectedMethod(method));
  };

  return (
    <div>
      <List>
        {paymentMethods.map((method) => (
          <ListItem
            key={method.id}
            onClick={() => handleMethodSelect(method)}
            style={{ color: 'var(--tg-theme-text-color)' }}
          >
            <ListItemIcon>
              <Radio
                checked={selectedMethod && selectedMethod.id === method.id}
                value="a"
                name="radio-buttons"
                inputProps={{ 'aria-label': 'A' }}
                sx={{ color: 'var(--tg-theme-hint-color)' }}
              />
            </ListItemIcon>
            <ListItemText primary={method.name} />
          </ListItem>
        ))}
      </List>
      <div className={styles.buttonSaveContainer}>
        <Button
          className={styles.buttonSave}
          variant="contained"
          disableElevation
          onClick={onSave}
          sx={{
            borderRadius: '25px',
            backgroundColor: 'var(--tg-theme-button-color)',
            color: 'var(--tg-theme-button-text-color)',
          }}
        >
          Save
        </Button>
      </div>
    </div>
  );
}

PaymentMethod.propTypes = {
  // onPaymentMethodSelect: PropTypes.func.isRequired,
  onSave: PropTypes.func.isRequired,
};

export default PaymentMethod;
