import React, { useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { Typography, AppBar, Toolbar, Badge } from "@mui/material";
import IconButton from "@mui/material/IconButton";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIosRounded";
import ShoppingCartRoundedIcon from "@mui/icons-material/ShoppingCartRounded";
import { Swiper, SwiperSlide } from "swiper/react";
import { Pagination } from "swiper/modules";
import "swiper/css";
import "swiper/css/pagination";
import { useDispatch, useSelector } from "react-redux";
import styles from "./ProductDetails.module.css";
import {
  selectCurrentProduct,
  getCurrentProductStatus,
  fetchProduct,
  clear,
} from "../../redux/productSlice";
import { getTotalCartItemCount } from "../../redux/cartSlice";
import {
  getStartParamUsing,
  setStartParamUsing,
} from "../../redux/startConfigSlice";
import { noImageURL } from "../../api/config";
import Characteristics from "./Characteristics";
import About from "./About";
import useTelegram from "../../hooks/useTelegram";
import ProductDetailsSkeleton from "./ProductDetailsSkeleton";
import AddProductButton from "../AddProductButton/AddProductButton";

function ProductDetails() {
  const { id } = useParams();
  const product = useSelector(selectCurrentProduct);
  const status = useSelector(getCurrentProductStatus);
  const isStartParamUsed = useSelector(getStartParamUsing);
  const totalCartItemCount = useSelector(getTotalCartItemCount);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { startParam } = useTelegram();

  useEffect(() => {
    if (status === "idle") {
      dispatch(fetchProduct(id));
    }
  }, [status, id, dispatch]);

  useEffect(
    () => () => {
      dispatch(clear());
    },
    [dispatch, id],
  );

  useEffect(() => {
    if (startParam && !isStartParamUsed) {
      dispatch(setStartParamUsing(true));
    }
  }, [startParam, setStartParamUsing, isStartParamUsed, dispatch]);

  const navigateToProducts = () => {
    navigate("/products");
  };
  const navigateToCart = () => {
    navigate("/cart");
  };

  if (status === "loading") {
    return <ProductDetailsSkeleton />;
  }

  if (!product) {
    return (
      <div className={styles.productDetailsContainer}>
        <AppBar
          position="fixed"
          sx={{
            backgroundColor: "var(--tg-theme-section-bg-color)",
            boxShadow: "none",
            height: "56px",
          }}
        >
          <Toolbar sx={{ height: "56px" }}>
            <IconButton onClick={navigateToProducts}>
              <ArrowBackIosIcon className={styles.headerIcon} />
            </IconButton>
            <div style={{ flexGrow: 1 }} />
            {/* Add flexGrow to push next icon to the right */}
            {/* <Checkbox
              icon={<FavoriteBorderIcon className={styles.headerIcon} />}
              checkedIcon={<FavoriteIcon />}
            /> */}
            <IconButton onClick={navigateToCart}>
              <Badge badgeContent={totalCartItemCount} color="error">
                <ShoppingCartRoundedIcon className={styles.headerIcon} />
              </Badge>
            </IconButton>
          </Toolbar>
        </AppBar>
        <Typography
          variant="h6"
          sx={{
            margin: "0px",
            padding: "150px 0px",
            color: "var(--tg-theme-destructive-text-color)",
          }}
        >
          Product not found
        </Typography>
      </div>
    );
  }

  return (
    <div className={styles.productDetailsContainer}>
      <AppBar
        position="fixed"
        sx={{
          backgroundColor: "transparent",
          // backgroundColor: 'var(--tg-theme-section-bg-color)',
          // boxShadow: 'none',
          boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.2)",
          height: "56px",
          backdropFilter: "blur(42px)",
          WebkitBackdropFilter: "blur(42px)",
          borderRadius: "0 0 26px 26px",
        }}
      >
        <Toolbar sx={{ height: "56px" }}>
          <IconButton onClick={navigateToProducts}>
            <ArrowBackIosIcon className={styles.headerIcon} />
          </IconButton>
          <Typography variant="body1" className={styles.headerText}>
            {product.title}
          </Typography>
          <div style={{ flexGrow: 1 }} />
          {/* Add flexGrow to push next icon to the right */}
          {/* <Checkbox
            icon={<FavoriteBorderIcon className={styles.headerIcon} />}
            checkedIcon={<FavoriteIcon />}
          /> */}
          <IconButton onClick={navigateToCart}>
            <Badge badgeContent={totalCartItemCount} color="error">
              <ShoppingCartRoundedIcon className={styles.headerIcon} />
            </Badge>
          </IconButton>
        </Toolbar>
      </AppBar>
      <div className={styles.productImageContainer}>
        <Swiper pagination modules={[Pagination]}>
          <SwiperSlide>
            <img src={product.mainMediaUrl || noImageURL} alt={product.title} />
          </SwiperSlide>

          {product.mediaFiles.map((mediaFile, index) => (
            <SwiperSlide key={index}>
              <img
                src={mediaFile.fileUrl || noImageURL}
                alt={mediaFile.fileUrl}
              />
            </SwiperSlide>
          ))}
        </Swiper>
      </div>
      <Typography variant="h5" className={styles.productName}>
        {product.title}
      </Typography>
      <Typography variant="body1" className={styles.productDescription}>
        {product.summary}
      </Typography>
      <About product={product} />
      <Characteristics product={product} />
      {/* <TonConnectButton /> */}
      <div className={styles.footer}>
        <Typography
          variant="body2"
          sx={{
            color: "var(--tg-theme-destructive-text-color)",
            fontSize: "20px",
            marginLeft: "16px",
          }}
        >
          {`${product.price} ${product.currencySymbol}`}
        </Typography>
        <AddProductButton
          product={product}
          className={styles.addButton}
          displayText
        />
      </div>
    </div>
  );
}

export default ProductDetails;
