import React from "react";
import {
  Typography,
  Divider,
  Button,
  Switch,
  InputBase,
  MenuItem,
  // NativeSelect,
  Select,
  // ListItemIcon,
} from "@mui/material";
// import FlagRoundedIcon from '@mui/icons-material/FlagRounded';
import { useFormik } from "formik";
import { useSelector, useDispatch } from "react-redux";
import { string, boolean, object } from "yup";
import { styled } from "@mui/material/styles";
import PropTypes from "prop-types";
import styles from "./ShippingForm.module.css";
import Label from "./Label";
import {
  addNewShippingInfo,
  updateShippingInfo,
} from "../../redux/shippingInfoSlice";
import { getAllCountries } from "../../redux/countriesSlice";
import { IMaskInput } from "react-imask";

const IOSSwitch = styled((props) => (
  // eslint-disable-next-line react/jsx-props-no-spreading
  <Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />
))(({ theme }) => ({
  width: 42,
  height: 26,
  padding: 0,
  "& .MuiSwitch-switchBase": {
    padding: 0,
    margin: 2,
    transitionDuration: "300ms",
    "&.Mui-checked": {
      transform: "translateX(16px)",
      color: "#fff",
      "& + .MuiSwitch-track": {
        backgroundColor: theme.palette.mode === "dark" ? "#2ECA45" : "#65C466",
        opacity: 1,
        border: 0,
      },
      "&.Mui-disabled + .MuiSwitch-track": {
        opacity: 0.5,
      },
    },
    "&.Mui-focusVisible .MuiSwitch-thumb": {
      color: "#33cf4d",
      border: "6px solid #fff",
    },
    "&.Mui-disabled .MuiSwitch-thumb": {
      color:
        theme.palette.mode === "light"
          ? theme.palette.grey[100]
          : theme.palette.grey[600],
    },
    "&.Mui-disabled + .MuiSwitch-track": {
      opacity: theme.palette.mode === "light" ? 0.7 : 0.3,
    },
  },
  "& .MuiSwitch-thumb": {
    boxSizing: "border-box",
    width: 22,
    height: 22,
  },
  "& .MuiSwitch-track": {
    borderRadius: 26 / 2,
    backgroundColor: theme.palette.mode === "light" ? "#E9E9EA" : "#39393D",
    opacity: 1,
    transition: theme.transitions.create(["background-color"], {
      duration: 500,
    }),
  },
}));

const InputZipMaskCustom = React.forwardRef(function TextMaskCustom(
  props,
  ref
) {
  const { onChange, ...other } = props;
  return (
    <IMaskInput
      {...other}
      mask="000000"
      type="number"
      definitions={{
        "#": /[1-9]/,
      }}
      inputRef={ref}
      onAccept={(value) => onChange({ target: { name: props.name, value } })}
      overwrite
    />
  );
});

const InputPhoneMaskCustom = React.forwardRef(function TextMaskCustom(
  props,
  ref
) {
  const { onChange, ...other } = props;
  return (
    <IMaskInput
      {...other}
      mask="000000000000000"
      type="number"
      definitions={{
        "#": /[1-9]/,
      }}
      inputRef={ref}
      onAccept={(value) => onChange({ target: { name: props.name, value } })}
      overwrite
    />
  );
});

const requiredError = "This field is required!";

function ShippingForm({ initialValues, onSave }) {
  const dispatch = useDispatch();

  const countries = useSelector(getAllCountries);

  const { id, ...initialShippingInfo } = initialValues || {};

  const showFutureUseButton = initialValues ? false : true;

  const formValues = initialValues
    ? { ...initialShippingInfo }
    : {
        address1: "",
        address2: "",
        city: "",
        state: "",
        countryId: "",
        zipCode: "",
        firstName: "",
        lastName: "",
        phone: "",
        futureUse: true,
      };

  const formik = useFormik({
    initialValues: formValues,
    validateOnChange: true,
    validationSchema: object().shape({
      address1: string()
        .max(100, "Address should be no more than 100 characters")
        .required(requiredError),
      address2: string().max(
        100,
        "Address should be no more than 100 characters"
      ),
      city: string()
        .max(100, "City should be no more than 50 characters")
        .required(requiredError),
      state: string()
        .max(100, "State should be no more than 50 characters")
        .required(requiredError),
      countryId: string().required(requiredError),
      zipCode: string().min(5).max(6).required(requiredError),
      firstName: string()
        .min(2, "First name must be at least 2 characters")
        .max(50, "First name should be no more than 50 characters")
        .required(requiredError),
      lastName: string()
        .min(2, "Last name must be at least 2 characters")
        .max(50, "Last name should be no more than 50 characters")
        .required(requiredError),
      phone: string().min(5).max(15).required(requiredError),
      futureUse: boolean(),
    }),
    initialTouched: {
      address1: false,
      address2: false,
      city: false,
      state: false,
      countryId: false,
      zipCode: false,
      firstName: false,
      lastName: false,
      phone: false,
    },
    onSubmit: async (formValues) => {
      const data = formValues;

      if (id) {
        await dispatch(updateShippingInfo({ ...data, id }));
      } else {
        await dispatch(addNewShippingInfo(data));
      }
      onSave();
    },
  });

  const {
    handleSubmit,
    setFieldValue,
    handleChange,
    handleBlur,
    values,
    errors,
    touched,
    isValid,
    dirty,
  } = formik;

  return (
    <form onSubmit={handleSubmit}>
      <div className={styles.sectionContainer}>
        <div className={styles.shippingLine}>
          <Label label="Address 1" />
          <InputBase
            onChange={handleChange}
            onBlur={handleBlur}
            placeholder="Address Line 1"
            name="address1"
            value={values.address1}
            sx={{ flex: 1, color: "var(--tg-theme-text-color)" }}
          />
        </div>
        <Divider
          className={
            errors.address1 && touched.address1 ? styles.errorDivider : ""
          }
        />
        <div className={styles.shippingLine}>
          <Label label="Address 2" />
          <InputBase
            onChange={handleChange}
            onBlur={handleBlur}
            placeholder="Address Line 2"
            name="address2"
            value={values.address2}
            sx={{ flex: 1, color: "var(--tg-theme-text-color)" }}
          />
        </div>
        <Divider
          className={
            errors.address2 && touched.address2 ? styles.errorDivider : ""
          }
        />
        <div className={styles.shippingLine}>
          <Label label="City" />
          <InputBase
            onChange={handleChange}
            onBlur={handleBlur}
            placeholder="City"
            name="city"
            value={values.city}
            sx={{ flex: 1, color: "var(--tg-theme-text-color)" }}
          />
        </div>
        <Divider
          className={errors.city && touched.city ? styles.errorDivider : ""}
        />
        <div className={styles.shippingLine}>
          <Label label="State" />
          <InputBase
            onChange={handleChange}
            onBlur={handleBlur}
            placeholder="State"
            name="state"
            value={values.state}
            sx={{ flex: 1, color: "var(--tg-theme-text-color)" }}
          />
        </div>
        <Divider
          className={errors.state && touched.state ? styles.errorDivider : ""}
        />
        <div className={styles.shippingLine}>
          <Label label="Country" />
          <Select
            id="country"
            label="Country"
            value={values.countryId}
            onChange={(e) => setFieldValue("countryId", e.target.value)}
            displayEmpty
            name="countryId"
            sx={{
              flex: 1,
              color: "var(--tg-theme-text-color)",
              "& .MuiSelect-select": {
                // display: 'flex',
                // alignItems: 'center',
                padding: "4px 0px 5px !important",
              },
              "& fieldset": {
                borderColor: "transparent !important",
                borderWidth: "0px !important",
              },
              "&:focus fieldset": {
                borderColor: "transparent !important",
                borderWidth: "0px !important",
              },
            }}
            InputLabelProps={{
              style: { color: "var(--tg-theme-subtitle-text-color)" },
            }}
            MenuProps={{
              PaperProps: {
                style: {
                  width: "100vw",
                  height: "100vh",
                  left: "0",
                  top: "0",
                  backgroundColor: "var(--tg-theme-section-bg-color)",
                  borderRadius: "16px",
                },
              },
            }}
          >
            <MenuItem value="" disabled>
              <Typography sx={{ color: "var(--tg-theme-hint-color)" }}>
                Select Country
              </Typography>
            </MenuItem>
            {/* <MenuItem value={1} sx={{ color: 'var(--tg-theme-text-color)' }}>
              United States
            </MenuItem>
            <MenuItem value={2} sx={{ color: 'var(--tg-theme-text-color)' }}>
              Canada
            </MenuItem> */}
            {/* Render countries dynamically here */}
            {countries.map((country) => (
              <MenuItem
                key={country.id}
                value={country.id}
                sx={{ color: "var(--tg-theme-text-color)" }}
              >
                {country.name}
              </MenuItem>
            ))}
          </Select>
        </div>
        <Divider
          className={
            errors.countryId && touched.countryId ? styles.errorDivider : ""
          }
        />
        <div className={styles.shippingLine}>
          <Label label="Zip Code" />
          <InputBase
            onChange={handleChange}
            onBlur={handleBlur}
            placeholder="Post code"
            name="zipCode"
            value={values.zipCode}
            inputComponent={InputZipMaskCustom}
            sx={{ flex: 1, color: "var(--tg-theme-text-color)" }}
          />
        </div>
        <Divider
          className={
            errors.zipCode && touched.zipCode ? styles.errorDivider : ""
          }
        />
      </div>
      <div className={styles.sectionContainer}>
        <div className={styles.shippingLine}>
          <Label label="First Name" />
          <InputBase
            onChange={handleChange}
            onBlur={handleBlur}
            placeholder="Name"
            name="firstName"
            value={values.firstName}
            sx={{ flex: 1, color: "var(--tg-theme-text-color)" }}
          />
        </div>
        <Divider
          className={
            errors.firstName && touched.firstName ? styles.errorDivider : ""
          }
        />
        <div className={styles.shippingLine}>
          <Label label="Last Name" />
          <InputBase
            onChange={handleChange}
            onBlur={handleBlur}
            placeholder="Surname"
            name="lastName"
            value={values.lastName}
            sx={{ flex: 1, color: "var(--tg-theme-text-color)" }}
          />
        </div>
        <Divider
          className={
            errors.lastName && touched.lastName ? styles.errorDivider : ""
          }
        />
        <div className={styles.shippingLine}>
          <Label label="Phone" />
          <InputBase
            onChange={handleChange}
            onBlur={handleBlur}
            placeholder="Phone"
            name="phone"
            value={values.phone}
            sx={{ flex: 1, color: "var(--tg-theme-text-color)" }}
            inputComponent={InputPhoneMaskCustom}
          />
        </div>
        <Divider
          className={errors.phone && touched.phone ? styles.errorDivider : ""}
        />
      </div>
      {showFutureUseButton && (
        <div className={styles.sectionContainer}>
          <div className={styles.shippingLine}>
            <Label label="Save for future use" />
            <IOSSwitch
              sx={{ m: 1 }}
              checked={values.futureUse}
              onChange={(e) => setFieldValue("futureUse", e.target.checked)}
            />
          </div>
        </div>
      )}
      <div className={styles.buttonSaveContainer}>
        <Button
          type="submit"
          className={styles.buttonSave}
          variant="contained"
          // color="primary"
          disableElevation
          disabled={!(isValid && dirty)}
          sx={{
            borderRadius: "25px",
            backgroundColor: "var(--tg-theme-button-color)",
            color: "var(--tg-theme-button-text-color)",
            "&.Mui-disabled": {
              // display: 'flex',
              // alignItems: 'center',
              color: "var(--tg-theme-subtitle-text-color) !important",
            },
          }}
        >
          Save
        </Button>
      </div>
    </form>
  );
}

ShippingForm.propTypes = {
  initialValues: PropTypes.shape({
    id: PropTypes.string.isRequired,
    address1: PropTypes.string.isRequired,
    address2: PropTypes.string,
    city: PropTypes.string.isRequired,
    state: PropTypes.string.isRequired,
    countryId: PropTypes.string.isRequired,
    zipCode: PropTypes.number.isRequired,
    firstName: PropTypes.string.isRequired,
    lastName: PropTypes.string.isRequired,
    phone: PropTypes.string.isRequired,
  }),
  onSave: PropTypes.func,
};

ShippingForm.defaultProps = {
  initialValues: null,
  onSave: () => {},
};

export default ShippingForm;
