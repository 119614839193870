/* eslint no-param-reassign: 0 */
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

export const fetchCountries = createAsyncThunk(
  "countries/fetchCountries",
  async (_, { getState, extra: { apiClientService }, rejectWithValue }) => {
    try {
      const token = getState().auth.accessToken;
      const response = await apiClientService.fetchCountries(token);

      if (response.status >= 400 && response.status <= 500 && response.status !== 401) {
        return rejectWithValue(response.data);
      }

      return response.data.data;
    } catch (error) {
      return rejectWithValue(error.message || "Failed to fetch countries");
    }
  },
);

export const countriesSlice = createSlice({
  name: "countries",
  initialState: {
    countries: [],
    status: "idle",
    error: null,
  },
  reducers: {
    load: (state) => {
      state.countries = [];
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchCountries.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchCountries.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.countries = action.payload || [];
      })
      .addCase(fetchCountries.rejected, (state, action) => {
        state.status = "failed";
        if (action.payload?.errors) {
          state.error = action.payload.errors[0].title;
        } else {
          state.error = action.payload || "An error occurred.";
        }
      });
  },
});

export const getAllCountries = (state) => state.countries.countries || [];
export const getCountriesStatus = (state) => state.countries.status;
export const getCountriesError = (state) => state.countries.error;

export const { load } = countriesSlice.actions;
