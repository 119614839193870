// OrderDetails.jsx
import React, { useEffect } from 'react';
// import PropTypes from 'prop-types';
import { useNavigate, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import {
  AppBar,
  Typography,
  Toolbar,
  IconButton,
  Badge,
  Stepper,
  Step,
  StepLabel,
} from '@mui/material';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIosRounded';
import ShoppingCartRoundedIcon from '@mui/icons-material/ShoppingCartRounded';
import PersonIcon from '@mui/icons-material/PersonOutlineRounded';
import WalletIcon from '@mui/icons-material/AccountBalanceWalletRounded';
import LocationIcon from '@mui/icons-material/LocationOnRounded';
import {
  selectCurrentOrder, getCurrentOrderStatus, fetchOrder, clear as clearOrder,
} from '../../redux/orderSlice';
import { getTotalCartItemCount } from '../../redux/cartSlice';
import {
  fetchOrderItemStatuses,
  getOrderItemStatuses,
  getSelectedItemId,
  setSelectedItemId,
  clear as clearStatuses,
} from '../../redux/orderItemStatusesSlice';
import getFormattedDate from '../../utils/getFormattedDate';
import getFormattedDateTime from '../../utils/getFormattedDateTime';
import getProductAmountLabel from '../../utils/getProductAmountLabel';
import styles from './OrderDetails.module.css';
import OrderDetailsItem from './OrderDetailsItem';
import OrderDetailsSkeleton from './OrderDetailsSkeleton';

function OrderDetails() {
  const { id } = useParams();
  const order = useSelector(selectCurrentOrder);
  const status = useSelector(getCurrentOrderStatus);
  const totalCartItemCount = useSelector(getTotalCartItemCount);
  const orderItemStatuses = useSelector(getOrderItemStatuses);
  const selectedItemId = useSelector(getSelectedItemId);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const itemsLabel = getProductAmountLabel(order?.items.length);

  const navigateToOrders = () => {
    navigate('/orders');
  };
  const navigateToCart = () => {
    navigate('/cart');
  };

  useEffect(() => {
    if (status === 'idle') {
      dispatch(fetchOrder(id));
    }
  }, [status, id, dispatch]);

  useEffect(() => {
    if (order && !selectedItemId) {
      dispatch(setSelectedItemId(order.items[0].itemId));
      dispatch(fetchOrderItemStatuses({
        orderId: order.id,
        itemId: order.items[0].itemId,
      }));
    }
  }, [selectedItemId, order, dispatch]);

  useEffect(() => (() => {
    dispatch(clearOrder());
    dispatch(clearStatuses());
  }), [dispatch, id]);

  if (status === 'loading') {
    return <OrderDetailsSkeleton />;
  }

  if (!order) {
    return (
      <div className={styles.orderDetailsContainer}>
        <AppBar
          position="fixed"
          sx={{
            backgroundColor: 'transparent',
            // backgroundColor: 'var(--tg-theme-section-bg-color)',
            // boxShadow: 'none',
            boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.2)',
            height: '56px',
            backdropFilter: 'blur(42px)',
            WebkitBackdropFilter: 'blur(42px)',
            borderRadius: '0 0 26px 26px',
          }}
        >
          <Toolbar sx={{ height: '56px', display: 'flex', justifyContent: 'space-between' }}>
            <IconButton onClick={navigateToOrders}>
              <ArrowBackIosIcon className={styles.headerIcon} />
            </IconButton>
            <IconButton onClick={navigateToCart}>
              <Badge badgeContent={totalCartItemCount} color="error">
                <ShoppingCartRoundedIcon className={styles.headerIcon} />
              </Badge>
            </IconButton>
          </Toolbar>
        </AppBar>
        <Typography variant="h6" sx={{ margin: '0px', padding: '150px 0px', color: 'var(--tg-theme-destructive-text-color)' }}>Order not found</Typography>
      </div>
    );
  }

  return (
    <div className={styles.orderDetailsContainer}>
      <AppBar
        position="fixed"
        sx={{
          backgroundColor: 'transparent',
          // backgroundColor: 'var(--tg-theme-section-bg-color)',
          // boxShadow: 'none',
          boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.2)',
          height: '56px',
          backdropFilter: 'blur(42px)',
          WebkitBackdropFilter: 'blur(42px)',
          borderRadius: '0 0 26px 26px',
        }}
      >
        <Toolbar sx={{ height: '56px', display: 'flex', justifyContent: 'space-between' }}>
          <IconButton onClick={navigateToOrders}>
            <ArrowBackIosIcon className={styles.headerIcon} />
          </IconButton>
          <div className={styles.headerText}>
            <Typography variant="h6" sx={{ color: 'var(--tg-theme-text-color)' }}>
              {`Order ${order.id}`}
            </Typography>
            <Typography variant="caption" sx={{ color: 'var(--tg-theme-hint-color)' }}>
              {`from ${getFormattedDate(order.createdAt)}`}
            </Typography>
          </div>
          <IconButton onClick={navigateToCart}>
            <Badge badgeContent={totalCartItemCount} color="error">
              <ShoppingCartRoundedIcon className={styles.headerIcon} />
            </Badge>
          </IconButton>
        </Toolbar>
      </AppBar>
      <div className={styles.orderDetails}>
        <Typography variant="h5" sx={{ color: 'var(--tg-theme-subtitle-text-color)' }}>
          {order.status}
        </Typography>
        <div className={styles.recieverSection}>
          <div className={styles.shippingIconContainer}>
            <LocationIcon className={styles.icon} />
          </div>
          <div className={styles.shippingInfoContainer}>
            <Typography variant="caption" sx={{ color: 'var(--tg-theme-hint-color)' }}>
              Delivery
            </Typography>
            <Typography variant="body2" sx={{ color: 'var(--tg-theme-text-color)' }}>
              {order.shippingAddress1}
            </Typography>
            <Typography variant="caption" sx={{ color: 'var(--tg-theme-hint-color)' }}>
              {order.shippingAddress2 ?? ''}
            </Typography>
          </div>
        </div>
        <div className={styles.shippingSection}>
          <div className={styles.shippingIconContainer}>
            <PersonIcon className={styles.icon} />
          </div>
          <div className={styles.shippingInfoContainer}>
            <Typography variant="caption" sx={{ color: 'var(--tg-theme-hint-color)' }}>
              Recipient
            </Typography>
            <Typography variant="body2" sx={{ color: 'var(--tg-theme-text-color)' }}>
              {order.receiverFullName}
            </Typography>
            <Typography variant="caption" sx={{ color: 'var(--tg-theme-hint-color)' }}>
              {order.receiverPhone}
            </Typography>
          </div>
        </div>
        <div className={styles.totalSection}>
          <div className={styles.shippingIconContainer}>
            <WalletIcon className={styles.icon} />
          </div>
          <div className={styles.totalContainer}>
            <div>
              <Typography
                variant="body2"
                sx={{
                  fontSize: '20px',
                  textAlign: 'left',
                  color: 'var(--tg-theme-text-color)',
                }}
              >
                Total
              </Typography>
              <Typography
                variant="body2"
                sx={{
                  textAlign: 'left',
                  color: 'var(--tg-theme-text-color)',
                }}
              >
                {itemsLabel}
              </Typography>
            </div>
            <div>
              <Typography
                variant="h5"
                sx={{
                  marginRight: '16px',
                  color: 'var(--tg-theme-text-color)',
                }}
              >
                {`${order.totalPrice} ${order.currencyCode}`}
              </Typography>
            </div>
          </div>
        </div>
        <Typography variant="h6" sx={{ color: 'var(--tg-theme-subtitle-text-color)' }}>
          Order composition
        </Typography>
        <div className={styles.orderDetailsItems}>
          {(order.items || []).map((item) => (
            <OrderDetailsItem
              key={item.itemId}
              item={item}
              orderId={order.id}
            />
          ))}
        </div>

        {/* Stepper to display order status history */}
        <div className={styles.stepperContainer}>
          <Stepper
            activeStep={orderItemStatuses.length}
            orientation="vertical"
          >
            {orderItemStatuses.map((step) => (
              <Step key={step.id} active>
                <StepLabel
                  StepIconProps={{
                    style: {
                      paddingLeft: '4px',
                      fontSize: 'large',
                      color: 'var(--tg-theme-hint-color)',
                      marginRight: '32px',
                    },
                  }}
                >
                  <Typography variant="body2" sx={{ color: 'var(--tg-theme-text-color)' }}>{step.statusName}</Typography>
                  <Typography variant="body2" sx={{ color: 'var(--tg-theme-hint-color)' }}>{getFormattedDateTime(step.createdAt)}</Typography>
                </StepLabel>
              </Step>
            ))}
          </Stepper>
        </div>
      </div>
    </div>
  );
}

// OrderDetails.propTypes = {
//   order: PropTypes.shape({
//     id: PropTypes.number.isRequired,
//     status: PropTypes.string.isRequired,
//     createdAt: PropTypes.string.isRequired,
//     totalPrice: PropTypes.number.isRequired,
//     paymentMethodInfo: PropTypes.string.isRequired,
//     shippingDetailInfo: PropTypes.string.isRequired,
//     items: PropTypes.instanceOf(Array).isRequired,
//   }).isRequired,
// };

export default OrderDetails;
