/* eslint no-param-reassign: 0 */
import { createSlice } from "@reduxjs/toolkit";

export const cartSlice = createSlice({
  name: "cart",
  initialState: {
    selectedProducts: [],
    status: "idle",
    error: null,
    currencyMismatch: false,
    currencyCode: null,
  },
  reducers: {
    addNewProductToCart: (state, action) => {
      const product = action.payload;

      const existingProduct = state.selectedProducts[0]; // Check first product in cart
      if (existingProduct && existingProduct.currencyCode !== product.currencyCode) {
        state.currencyMismatch = true; // Trigger currency mismatch
        return;
      }
      if (!state.currencyCode) {
        state.currencyCode = product.currencyCode;
      }

      state.currencyMismatch = false;
      const productInCart = state.selectedProducts.find((p) => p.id === product.id);
      if (!productInCart) {
        state.selectedProducts = [
          ...state.selectedProducts,
          { ...product, count: 1 },
        ];
      } else {
        state.selectedProducts = state.selectedProducts.map((p) => (
          p.id === product.id ? { ...product, count: p.count + 1 } : p
        ));
      }
    },
    deleteProductFromCart: (state, action) => {
      const product = action.payload;
      const productInCart = state.selectedProducts.find((p) => p.id === product.id);

      if (productInCart.count > 1) {
        state.selectedProducts = state.selectedProducts.map((p) => (
          p.id === product.id ? { ...product, count: p.count - 1 } : p
        ));
      } else {
        state.selectedProducts = state.selectedProducts.filter((p) => (
          p.id !== product.id
        ));
      }

      if (state.selectedProducts.length === 0) {
        state.currencyCode = null;
      }
    },
    clearCart: (state) => {
      state.selectedProducts = [];
      state.status = "idle";
      state.error = null;
      state.currencyMismatch = false;
      state.currencyCode = null;
    },
    resolveCurrencyMismatch: (state, action) => {
      if (action.payload === "replace") {
        state.selectedProducts = []; // Clear the cart
        state.currencyCode = null;
      }
      state.currencyMismatch = false; // Reset the mismatch state
    },
  },
});

export const selectAllCartProducts = (state) => state.cart.selectedProducts || [];
export const getTotalCartItemCount = (state) => (state.cart.selectedProducts || [])
  .reduce((total, product) => total + product.count, 0);
export const getCartPrice = (state) => (state.cart.selectedProducts || [])
  .reduce((acc, p) => (acc + p.price * p.count), 0);
export const getCurrentCartStatus = (state) => state.cart.status;
export const isCurrencyMismatch = (state) => state.cart.currencyMismatch;
export const getCartCurrencyCode = (state) => state.cart.currencyCode;

export const {
  clearCart,
  addNewProductToCart,
  deleteProductFromCart,
  resolveCurrencyMismatch,
} = cartSlice.actions;
