/* eslint no-param-reassign: 0 */
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

export const fetchShippingInfos = createAsyncThunk(
  "shippingInfo/fetchShippingInfos",
  async (arg, { getState, extra: { apiClientService }, rejectWithValue }) => {
    try {
      const token = getState().auth.accessToken;
      const response = await apiClientService.fetchShippingInfos(token);
      if (response.status >= 400 && response.status <= 500 && response.status !== 401) {
        return rejectWithValue(response.data);
      }
      return response.data.data;
    } catch (error) {
      return rejectWithValue(error.response?.data || error.message);
    }
  },
);

export const switchDefaultShippingInfo = createAsyncThunk(
  "shippingInfo/switchDefault",
  async (shippingId, { getState, extra: { apiClientService }, rejectWithValue }) => {
    try {
      const token = getState().auth.accessToken;
      const response = await apiClientService.switchDefaultShippingInfo(shippingId, token);
      if (response.status >= 400 && response.status <= 500 && response.status !== 401) {
        return rejectWithValue(response.data);
      }
      return shippingId;
    } catch (error) {
      return rejectWithValue(error.response?.data || error.message);
    }
  },
);

export const addNewShippingInfo = createAsyncThunk(
  "shippingInfo/addNewShippingInfo",
  async (data, { getState, extra: { apiClientService }, rejectWithValue }) => {
    try {
      const token = getState().auth.accessToken;
      const response = await apiClientService.addNewShippingInfo(data, token);
      if (response.status >= 400 && response.status <= 500 && response.status !== 401) {
        return rejectWithValue(response.data);
      }
      return response.data.data;
    } catch (error) {
      return rejectWithValue(error.response?.data || error.message);
    }
  },
);

export const updateShippingInfo = createAsyncThunk(
  "shippingInfo/updateShippingInfo",
  async (data, { getState, extra: { apiClientService }, rejectWithValue }) => {
    try {
      const token = getState().auth.accessToken;
      const response = await apiClientService.updateShippingInfo(data, token);
      if (response.status >= 400 && response.status <= 500 && response.status !== 401) {
        return rejectWithValue(response.data);
      }
      return response.data.data;
    } catch (error) {
      return rejectWithValue(error.response?.data || error.message);
    }
  },
);

export const shippingInfoSlice = createSlice({
  name: "shippingInfo",
  initialState: {
    shippingInfos: [],
    status: "idle",
    error: null,
  },
  reducers: {
    load: (state) => {
      state.shippingInfos = [];
    },
  },
  extraReducers(builder) {
    builder
      .addCase(fetchShippingInfos.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchShippingInfos.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.shippingInfos = action.payload || [];
      })
      .addCase(fetchShippingInfos.rejected, (state, action) => {
        state.status = "failed";
        if (action.payload?.errors) {
          state.error = action.payload.errors[0].title;
        } else {
          state.error = action.payload || "An error occurred.";
        }
      })
      .addCase(switchDefaultShippingInfo.pending, (state) => {
        state.status = "loading";
      })
      .addCase(switchDefaultShippingInfo.fulfilled, (state, action) => {
        state.status = "succeeded";
        const defaultShippingId = action.payload || null;
        state.shippingInfos = state.shippingInfos.map((s) => (
          s.id === defaultShippingId
            ? ({
              ...s,
              default: true,
            })
            : ({
              ...s,
              default: false,
            })
        ));
      })
      .addCase(switchDefaultShippingInfo.rejected, (state, action) => {
        state.status = "failed";
        if (action.payload?.errors) {
          state.error = action.payload.errors[0].title;
        } else {
          state.error = action.payload || "An error occurred.";
        }
      })
      // Add new shipping info
      .addCase(addNewShippingInfo.pending, (state) => {
        state.status = "loading";
      })
      // .addCase(addNewShippingInfo.fulfilled, (state, action) => {
      //   state.status = "succeeded";
      //   state.shippingInfos.push(action.payload); // Assuming `action.payload` is the new shipping info
      // })
      .addCase(addNewShippingInfo.rejected, (state, action) => {
        state.status = "failed";
        if (action.payload?.errors) {
          state.error = action.payload.errors[0].title;
        } else {
          state.error = action.payload || "An error occurred.";
        }
      })
      // Update shipping info
      .addCase(updateShippingInfo.pending, (state) => {
        state.status = "loading";
      })
      // .addCase(updateShippingInfo.fulfilled, (state, action) => {
      //   state.status = "succeeded";
      //   const updatedInfo = action.payload;
      //   state.shippingInfos = state.shippingInfos.map((s) =>
      //     s.id === updatedInfo.id ? updatedInfo : s
      //   );
      // })
      .addCase(updateShippingInfo.rejected, (state, action) => {
        state.status = "failed";
        if (action.payload?.errors) {
          state.error = action.payload.errors[0].title;
        } else {
          state.error = action.payload || "An error occurred.";
        }
      });
  },
});

export const selectAllShippingInfos = (state) => state.shippingInfo.shippingInfos || [];
export const selectDefaultShipping = (state) => (
  (state.shippingInfo.shippingInfos || [])
    .find((s) => !!s.default) || null);
export const getshippingInfoStatus = (state) => state.shippingInfo.status;
export const getShippingInfoError = (state) => state.shippingInfo.error;

export const { load } = shippingInfoSlice.actions;
