import React, { useState, useEffect, useCallback } from "react";
import { useNavigate } from "react-router-dom";
import {
  AppBar,
  Toolbar,
  IconButton,
  Typography,
  Button,
  Divider,
  SwipeableDrawer,
  TextareaAutosize,
  Box,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  ListItemButton,
} from "@mui/material";
import ArrowBackIosRoundedIcon from "@mui/icons-material/ArrowBackIosRounded";
import HighlightOffRoundedIcon from "@mui/icons-material/HighlightOffRounded";
import PaymentIcon from "@mui/icons-material/PaymentRounded";
import LocationIcon from "@mui/icons-material/LocationOnRounded";
import PersonIcon from "@mui/icons-material/PersonOutlineRounded";
import PhoneIcon from "@mui/icons-material/PhoneIphoneRounded";
import { styled } from "@mui/material/styles";
import { TonConnectButton, useTonConnectUI, useTonWallet } from "@tonconnect/ui-react";
import { useSelector, useDispatch } from "react-redux";
import styles from "./Order.module.css";
import OrderItem from "./OrderItem";
import ShippingInfo from "../Shipping/ShippingForm";
import PaymentMethodInfo from "./PaymentMethod";
import {
  selectAllCartProducts,
  getCartPrice,
  getCartCurrencyCode,
  clearCart,
} from "../../redux/cartSlice";
import {
  fetchShippingInfos,
  selectDefaultShipping,
  selectAllShippingInfos,
  getshippingInfoStatus,
} from "../../redux/shippingInfoSlice";
import {
  addOrder,
  clearOrders,
} from "../../redux/ordersSlice";
import {
  selectedPaymentMethod,
  fetchPaymentMethods,
  getPaymentMethodsStatus,
} from "../../redux/paymentMethodsSlice";
import toNano from "../../utils/toNano";
import { fetchCountries, getCountriesStatus } from "../../redux/countriesSlice";

function Checkout() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const products = useSelector(selectAllCartProducts);
  const currentPaymentMethod = useSelector(selectedPaymentMethod);
  const price = useSelector(getCartPrice);
  const shippingInfos = useSelector(selectAllShippingInfos);
  const defaultShipping = useSelector(selectDefaultShipping);
  const shippingInfoStatus = useSelector(getshippingInfoStatus);
  const paymentMethodsStatus = useSelector(getPaymentMethodsStatus);
  const countriesStatus = useSelector(getCountriesStatus);
  const currencyCode = useSelector(getCartCurrencyCode);
  const [drawerOpen, setOpenDrawer] = useState(false);
  const [comment, setComment] = useState("");
  const [activeInfo, setActiveInfo] = useState();
  const [tonConnectUI] = useTonConnectUI();
  const wallet = useTonWallet();

  useEffect(() => {
    if (!shippingInfos.length && shippingInfoStatus === "idle") {
      dispatch(fetchShippingInfos());
    }
  }, [dispatch, shippingInfos, shippingInfoStatus]);

  useEffect(() => {
    if (paymentMethodsStatus === "idle") {
      dispatch(fetchPaymentMethods());
    }
  }, [paymentMethodsStatus, dispatch]);

  useEffect(() => {
    if (countriesStatus === "idle") {
      dispatch(fetchCountries());
    }
  }, [countriesStatus, dispatch]);

  const handleSaveAddress = useCallback(async () => {
    dispatch(fetchShippingInfos());
    setOpenDrawer(false);
  }, [dispatch]);

  const handleSavePaymentMethod = () => {
    setOpenDrawer(false);
  };

  const deliveryInfo = [
    {
      id: "payment",
      title: "Payment Method",
      icon: PaymentIcon,
      value: currentPaymentMethod?.name || "",
      component: <PaymentMethodInfo
        onSave={handleSavePaymentMethod}
      />,
    },
    {
      id: "delivery",
      title: "Delivery Address",
      icon: LocationIcon,
      value: `${defaultShipping?.address1 || ""} ${defaultShipping?.address2 || ""}`,
      component: <ShippingInfo initialValues={defaultShipping} onSave={handleSaveAddress} />,
    },
    {
      id: "name",
      title: "Name",
      icon: PersonIcon,
      value: `${defaultShipping?.firstName || ""} ${defaultShipping?.lastName || ""}`,
      component: <ShippingInfo initialValues={defaultShipping} onSave={handleSaveAddress} />,
    },
    {
      id: "phone",
      title: "Phone number",
      icon: PhoneIcon,
      value: defaultShipping?.phone || "",
      component: <ShippingInfo initialValues={defaultShipping} onSave={handleSaveAddress} />,
    },
  ];

  const toncoinTransaction = {
    // The transaction is valid for 10 minutes from now, in unix epoch seconds.
    validUntil: Math.floor(Date.now() / 1000) + 600,
    messages: [
      {
        address: "UQDiH54haPfraV3yoqPaBydcnYrFGdjbcndTVlSvXWIcykWB",
        amount: toNano(price).toString(), // "20000000", // toNano("0.02").toString(),
        // just for instance.
        // Replace with your transaction initState or remove
        // stateInit: "base64bocblahblahblah=="
      },
      // {
      //   address: "EQDmnxDMhId6v1Ofg_h5KR5coWlFG6e86Ro3pc7Tq4CA0-Jn",
      //   amount: "60000000",
      //   payload: "base64bocblahblahblah==",
      // },
    ],
  };

  const handleDrawerClose = () => {
    setOpenDrawer(false);
  };

  const handleDrawerOpen = (infoId) => {
    setOpenDrawer(true);
    setActiveInfo(deliveryInfo.find(({ id }) => id === infoId));
  };

  const handleCommentChange = (event) => {
    setComment(event.target.value);
  };

  const handleMakeOrder = useCallback(async () => {
    if (!defaultShipping) {
      handleDrawerOpen("name");
      return;
    }
    if (!currentPaymentMethod) {
      handleDrawerOpen("payment");
      return;
    }

    if (currentPaymentMethod.code === "Crypto" && wallet) {
      tonConnectUI.sendTransaction(toncoinTransaction, {
        modals: ["before", "success", "error"],
        notifications: ["before", "success", "error"],
        skipRedirectToWallet: "never", // "ios" (default), or "never", or "always"
      });
    }

    const orderData = {
      currencyCode,
      paymentMethodId: currentPaymentMethod.id,
      shippingDetailId: defaultShipping.id,
      totalPrice: price,
      items: products.map((product) => ({
        productId: product.id,
        quantity: product.count,
        price: product.price,
      })),
    };

    try {
      await dispatch(addOrder(orderData)).unwrap();
      dispatch(clearCart());
      dispatch(clearOrders());
      if (currentPaymentMethod.code === "Card") {
        navigate("/checkout/stripe-checkout");
      } else {
        navigate("/products");
      }
    } catch (error) {
      // handle error, maybe show a message to the user
    }

    // dispatch(addOrder(orderData));
    // dispatch(clearCart());
    // // dispatch(clearSelectedMethod());
    // dispatch(clearOrders());
    // // Redirect or perform any other actions after placing the order
    // navigate("/products");
  }, [
    defaultShipping,
    currentPaymentMethod,
    wallet,
    products,
    price,
    dispatch,
    navigate,
    handleDrawerOpen,
    tonConnectUI,
    toncoinTransaction]);

  const Puller = styled(Box)(() => ({
    width: 40,
    height: 6,
    backgroundColor: "var(--tg-theme-hint-color)",
    borderRadius: 3,
    position: "absolute",
    top: 8,
    left: "calc(50% - 20px)",
  }));

  const CloseIcon = styled(HighlightOffRoundedIcon)(() => ({
    position: "absolute",
    top: "20px",
    right: "20px",
    color: "var(--tg-theme-hint-color)",
    "&:hover": {
      color: "var(--tg-theme-section-header-text-color)",
    },
  }));

  const getMakeOrderButton = () => {
    if (currentPaymentMethod?.code === "Crypto" && !wallet) {
      return <div />;
    }
    return (
      <div className={styles.footer}>
        <Button
          variant="contained"
          disableElevation
          onClick={handleMakeOrder}
          sx={
            {
              width: "95%",
              height: "50px",
              backgroundColor: "var(--tg-theme-button-color)",
              color: "var(--tg-theme-button-text-color)",
              borderRadius: "25px",
            }
          }
        >
          Make Order
        </Button>
      </div>
    );
  };

  return (
    <div className={styles.pageContainer}>
      <AppBar
        position="fixed"
        sx={{
          backgroundColor: "transparent",
          // backgroundColor: "var(--tg-theme-section-bg-color)",
          // boxShadow: "none",
          boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.2)",
          height: "56px",
          backdropFilter: "blur(42px)",
          WebkitBackdropFilter: "blur(42px)",
          borderRadius: "0 0 26px 26px",
        }}
      >
        <Toolbar sx={{ height: "56px" }}>
          <IconButton onClick={() => navigate("/cart")}>
            <ArrowBackIosRoundedIcon className={styles.icon} />
          </IconButton>
        </Toolbar>
      </AppBar>
      <div className={styles.sectionContainer}>
        <Box sx={{
          width: "100%",
          bgcolor: "var(--tg-theme-section-bg-color)",
          color: "var(--tg-theme-text-color)",
        }}
        >
          <nav aria-label="main mailbox folders">
            <List>
              {deliveryInfo.map((i) => (
                <ListItem disablePadding>
                  <ListItemButton onClick={() => handleDrawerOpen(i.id)}>
                    <ListItemIcon>
                      <i.icon className={styles.icon} />
                    </ListItemIcon>
                    <ListItemText
                      primary={i.value}
                      secondary={(
                        <Typography variant="body2" color="var(--tg-theme-subtitle-text-color)">
                          {i.title}
                        </Typography>
                      )}
                    />
                  </ListItemButton>
                </ListItem>
              ))}
            </List>
          </nav>
        </Box>
      </div>
      <div className={styles.sectionContainer}>
        <div className={styles.orderHeader}>
          <Typography
            variant="body2"
            sx={{
              fontSize: "20px",
              marginLeft: "16px",
              textAlign: "left",
              color: "var(--tg-theme-text-color)",
            }}
          >
            Order
          </Typography>
        </div>
        <div className={styles.cartItems}>
          {products.map((product) => (
            <OrderItem key={product.id} product={product} />
          ))}
          <Divider />
          <div className={styles.totalInfoContainer}>
            <Typography
              variant="body1"
              sx={{
                marginLeft: "16px",
                color: "var(--tg-theme-text-color)",
              }}
            >
              Total
            </Typography>
            <Typography
              variant="body1"
              sx={{
                marginRight: "16px",
                color: "var(--tg-theme-text-color)",
              }}
            >
              {`${price} ${currencyCode}`}
            </Typography>
          </div>
        </div>
      </div>
      {/* <div className={styles.sectionContainer}>
        {deliveryInfo.map((info, index) => (
          <>
            <div className={styles.shippingLine}>
              <Typography
                variant="subtitle1"
                className={styles.nonEditableText}
                sx={{ marginRight: "10px", color: "var(--tg-theme-subtitle-text-color)" }}
              >
                {info.title}
              </Typography>
              <Typography
                variant="subtitle1"
                className={styles.editableText}
                sx={{ color: "var(--tg-theme-text-color)" }}
              >
                {info.value}
              </Typography>
              <IconButton onClick={() => handleDrawerOpen(info.id)}>
                <EditIcon />
              </IconButton>
            </div>
            {(index + 1) < deliveryInfo.length
              && <Divider />}
          </>
        ))}
      </div> */}
      <div className={styles.sectionContainer}>
        <TextareaAutosize
          aria-label="comment"
          minRows={1}
          maxRows={10}
          placeholder="Add comment..."
          value={comment}
          onChange={handleCommentChange}
          style={{
            width: "100%",
            border: "none",
            fontSize: "16px",
            fontFamily: "Roboto, sans-serif", // Use the Roboto font family
            boxSizing: "border-box", // Include padding within the specified width
            outline: "none", // Remove the outline when focused
            backgroundColor: "var(--tg-theme-section-bg-color)",
            color: "var(--tg-theme-text-color)",
          }}
        />
      </div>
      { currentPaymentMethod?.code === "Crypto"
        && (
          <div className={styles.tonconnectButtonSection}>
            <Typography
              variant="subtitle1"
              className={styles.nonEditableText}
              sx={{
                marginBottom: "10px",
                color: "var(--tg-theme-subtitle-text-color)",
              }}
            >
              TON Wallet
            </Typography>
            <TonConnectButton className={styles.tonconnectButtom} />
          </div>
        ) }
      {getMakeOrderButton()}
      <SwipeableDrawer
        anchor="bottom"
        open={drawerOpen}
        onClose={handleDrawerClose}
        onOpen={handleDrawerOpen}
        PaperProps={{
          sx: {
            borderTopLeftRadius: 26,
            borderTopRightRadius: 26,
            backgroundColor: "var(--tg-theme-secondary-bg-color)",
          },
        }}
      >
        <Puller />
        <CloseIcon onClick={handleDrawerClose} />
        <div
          style={{
            padding: "16px",
            display: "flex",
            flexDirection: "column",
          }}
        >
          <Typography
            variant="h6"
            fontWeight="bold"
            sx={{ paddingBottom: "10px", color: "var(--tg-theme-section-header-text-color)" }}
          >
            {activeInfo?.title}
          </Typography>
          {activeInfo?.component}
        </div>
      </SwipeableDrawer>
    </div>
  );
}

export default Checkout;
