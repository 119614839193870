import React from "react";
import PropTypes from "prop-types";
import {
  Divider,
  Typography,
  Button,
  // Checkbox,
} from "@mui/material";
import RemoveRoundedIcon from "@mui/icons-material/RemoveRounded";
import AddRoundedIcon from "@mui/icons-material/AddRounded";
// import FavoriteBorderIcon from '@mui/icons-material/FavoriteBorderRounded';
// import FavoriteIcon from '@mui/icons-material/FavoriteRounded';
import { useDispatch } from "react-redux";
import styles from "./CartItem.module.css";
import {
  addNewProductToCart,
  deleteProductFromCart,
} from "../../redux/cartSlice";
import { noImageURL } from "../../api/config";
import { useNavigate } from "react-router-dom";

function CartItem({ product }) {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const navigateToProductCard = (id) => {
    navigate(`/products/${id}`);
  };

  const handleIncrement = (event) => {
    event.preventDefault();
    dispatch(addNewProductToCart(product));
  };

  const handleDecrement = (event) => {
    event.preventDefault();
    dispatch(deleteProductFromCart(product));
  };

  return (
    <div>
      <div
        className={styles.cartItem}
        onClick={() => navigateToProductCard(product.id)}
      >
        <div className={styles.imageContainer}>
          <img src={product.mainMediaUrl || noImageURL} alt={product.title} />
        </div>
        <div className={styles.productDetails}>
          <Typography
            variant="body1"
            sx={{ color: "var(--tg-theme-text-color)" }}
          >
            {product.summary}
          </Typography>
          <Typography variant="h6" sx={{ color: "var(--tg-theme-text-color)" }}>
            {`${product.price} ${product.currencySymbol}`}
          </Typography>
        </div>
      </div>
      <div className={styles.itemButtons}>
        {/* <Checkbox
          icon={<FavoriteBorderIcon />}
          checkedIcon={<FavoriteIcon />}
          sx={{
            paddingRight: '30px',
          }}
        /> */}
        <Button
          variant="contained"
          disableElevation
          sx={{
            borderRadius: "20px",
            // backgroundColor: '#f3f3f3',
            // color: '#333',
            backgroundColor: "var(--tg-theme-button-color)",
            color: "var(--tg-theme-text-color)",
          }}
        >
          <div className={styles.itemButtonAddRemove}>
            <RemoveRoundedIcon onClick={handleDecrement} />
            <Typography sx={{ padding: "0px 15px 0px" }}>
              {product.count}
            </Typography>
            <AddRoundedIcon onClick={handleIncrement} />
          </div>
        </Button>
      </div>
    </div>
  );
}

CartItem.propTypes = {
  product: PropTypes.shape({
    id: PropTypes.number.isRequired,
    mainMediaUrl: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    summary: PropTypes.string.isRequired,
    currencySymbol: PropTypes.string.isRequired,
    price: PropTypes.number.isRequired,
    count: PropTypes.number.isRequired,
  }).isRequired,
};

export default CartItem;
