import { configureStore } from "@reduxjs/toolkit";
import { productsSlice } from "../redux/productsSlice";
import { productSlice } from "../redux/productSlice";
import { shippingInfoSlice } from "../redux/shippingInfoSlice";
import { cartSlice } from "../redux/cartSlice";
import { ordersSlice } from "../redux/ordersSlice";
import { orderSlice } from "../redux/orderSlice";
import { paymentMethodsSlice } from "../redux/paymentMethodsSlice";
import { authSlice } from "../redux/authSlice";
import { countriesSlice } from "../redux/countriesSlice";
import { startConfigSlice } from "../redux/startConfigSlice";
import { orderItemStatusesSlice } from "../redux/orderItemStatusesSlice";
import createApiClientService from "../api/apiService";

const store = configureStore({
  reducer: {
    products: productsSlice.reducer,
    product: productSlice.reducer,
    shippingInfo: shippingInfoSlice.reducer,
    cart: cartSlice.reducer,
    orders: ordersSlice.reducer,
    paymentMethods: paymentMethodsSlice.reducer,
    auth: authSlice.reducer,
    order: orderSlice.reducer,
    countries: countriesSlice.reducer,
    startConfig: startConfigSlice.reducer,
    orderItemStatuses: orderItemStatusesSlice.reducer,
  },
  middleware: (getDefaultMiddleware) => getDefaultMiddleware({
    thunk: {
      extraArgument: {
        apiClientService: createApiClientService(),
      },
    },
  }),
});

export default store;
