import React from 'react';
import {
  Skeleton as MuiSkeleton,
  AppBar,
  Toolbar,
  IconButton,
  Button,
} from '@mui/material';
import LocationOnIcon from '@mui/icons-material/LocationOnRounded';
import ShoppingCartRoundedIcon from '@mui/icons-material/ShoppingCartRounded';
import TimelineRoundedIcon from '@mui/icons-material/TimelineRounded';
// import AddIcon from '@mui/icons-material/AddRounded';
import styles from './Skeleton.module.css';

function Skeleton() {
  return (
    <div className={styles.container}>
      <AppBar
        position="fixed"
        sx={{
          backgroundColor: 'var(--tg-theme-section-bg-color)',
          boxShadow: 'none',
          height: '56px',
          borderRadius: '0 0 26px 26px',
        }}
      >
        <Toolbar sx={{ height: '56px' }}>
          <IconButton>
            <LocationOnIcon className={styles.locationIcon} />
          </IconButton>
          <MuiSkeleton variant="text" animation="wave" width={200} sx={{ fontSize: '1.4rem' }} />
          <div style={{ flexGrow: 1 }} />
          {/* Add flexGrow to push next icon to the right */}
          <IconButton>
            <TimelineRoundedIcon className={styles.headerIcon} />
          </IconButton>
          <IconButton>
            <ShoppingCartRoundedIcon className={styles.headerIcon} />
          </IconButton>
        </Toolbar>
      </AppBar>
      <div className={styles.skeletonList}>
        {[1, 2, 3, 4, 5, 6, 8, 9, 10, 11, 12, 13].map((index) => (
          <div key={index} className={styles.skeletonItem}>
            <div className={styles.skeletonImg}>
              {/* <MuiSkeleton variant="rounded" width={100} height={100} /> */}
              <MuiSkeleton
                variant="rectangular"
                animation="wave"
                width="100%"
                height="100%"
                sx={{
                  borderRadius: '21px',
                  paddingTop: '100%',
                }}
              />
            </div>
            <div className={styles.productDetails}>
              <MuiSkeleton variant="text" width={150} sx={{ fontSize: '1.2rem' }} />
              <MuiSkeleton variant="text" width={100} sx={{ fontSize: '1.2rem' }} />
              <MuiSkeleton variant="text" width={50} sx={{ fontSize: '1.2rem' }} />
            </div>
            <div className={styles.cartButton}>
              <Button
                variant="contained"
                disableElevation
              />
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}

export default Skeleton;
