import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import {
  Typography,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@mui/material';
import AddIcon from '@mui/icons-material/AddRounded';
import classNames from 'classnames';
import RemoveIcon from '@mui/icons-material/RemoveRounded';
import styles from './AddProductButton.module.css';
import {
  addNewProductToCart,
  deleteProductFromCart,
  selectAllCartProducts,
  resolveCurrencyMismatch,
} from '../../redux/cartSlice';

function AddProductButton({ product, className, displayText }) {
  const dispatch = useDispatch();
  const productsInCart = useSelector(selectAllCartProducts);
  const [openDialog, setOpenDialog] = useState(false);

  const handleAddToCart = (event) => {
    event.preventDefault();
    const existingProduct = productsInCart[0];
    if (existingProduct && existingProduct.currencyCode !== product.currencyCode) {
      setOpenDialog(true);
      return;
    }
    dispatch(addNewProductToCart(product));
  };

  const handleRemoveFromCart = (event) => {
    event.preventDefault();
    dispatch(deleteProductFromCart(product));
  };

  const handleDialogClose = (event, action) => {
    event.stopPropagation();
    if (action === 'replace') {
      dispatch(resolveCurrencyMismatch('replace'));
      dispatch(addNewProductToCart(product)); // Re-add product after clearing cart
    } else {
      dispatch(resolveCurrencyMismatch('cancel'));
    }
    setOpenDialog(false);
  };

  const productInCart = productsInCart.find((p) => p.id === product.id);

  return (
    <>
      {productInCart ? (
        <div className={classNames(styles.cartButton, className)}>
          <Button
            variant="contained"
            disableElevation
            onClick={(e) => { e.stopPropagation(); e.preventDefault(); }}
          >
            <div className={styles.cartButtonContainer}>
              <RemoveIcon onClick={handleRemoveFromCart} />
              <Typography>{productInCart.count}</Typography>
              <AddIcon onClick={handleAddToCart} />
            </div>
          </Button>
        </div>
      ) : (
        <div className={classNames(styles.cartButton, className)}>
          <Button
            variant="contained"
            disableElevation
            onClick={handleAddToCart}
          >
            { displayText ? (
              <Typography sx={{ textTransform: 'none' }}>Add to Cart</Typography>
            ) : (
              <AddIcon />
            )}
          </Button>
        </div>
      )}
      <Dialog
        open={openDialog}
        onClose={(e) => handleDialogClose(e, 'cancel')}
        sx={{
          '& .MuiDialog-paper': {
            backgroundColor: 'var(--tg-theme-section-bg-color)',
            color: 'var(--tg-theme-text-color)',
            borderRadius: '26px',
            padding: '8px',
          },
        }}
      >
        <DialogTitle>
          <Typography className={styles.dialogTitle} variant="h5">
            Currency Mismatch
          </Typography>
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            <Typography className={styles.dialogDescription} variant="body1">
              The product you are trying to add has a different currency than the items
              currently in your cart. Would you like to clear the cart and add this
              product, or cancel the addition?
            </Typography>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={(e) => handleDialogClose(e, 'cancel')}
            sx={{
              color: 'var(--tg-theme-button-color)',
            }}
          >
            <Typography>Cancel</Typography>
          </Button>
          <Button
            onClick={(e) => handleDialogClose(e, 'replace')}
            sx={{
              color: 'var(--tg-theme-destructive-text-color)',
            }}
          >
            <Typography>Replace Cart</Typography>
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}

AddProductButton.propTypes = {
  product: PropTypes.shape({
    id: PropTypes.number.isRequired,
    mainMediaUrl: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    summary: PropTypes.string.isRequired,
    price: PropTypes.number.isRequired,
    currencySymbol: PropTypes.string.isRequired,
    currencyCode: PropTypes.string.isRequired,
  }).isRequired,
  className: PropTypes.string,
  displayText: PropTypes.bool,
};

AddProductButton.defaultProps = {
  className: '',
  displayText: false,
};

export default AddProductButton;
