import React from "react";
import PropTypes from "prop-types";
import { Divider, Typography } from "@mui/material";
import styles from "./OrderItem.module.css";
import { noImageURL } from "../../api/config";
import { useNavigate } from "react-router-dom";

function OrderItem({ product }) {
  const navigate = useNavigate();

  const navigateToProductCard = (id) => {
    navigate(`/products/${id}`);
  };

  return (
    <div>
      <Divider />
      <div
        className={styles.orderItem}
        onClick={() => navigateToProductCard(product.id)}
      >
        <div className={styles.imageContainer}>
          <img src={product.mainMediaUrl || noImageURL} alt={product.title} />
        </div>
        <div className={styles.productDetails}>
          <Typography
            variant="body1"
            sx={{ color: "var(--tg-theme-text-color)" }}
          >
            {product.summary}
          </Typography>
          <Typography variant="h7" sx={{ color: "var(--tg-theme-text-color)" }}>
            {`${product.price} ${product.currencySymbol}`}
          </Typography>
        </div>
      </div>
    </div>
  );
}

OrderItem.propTypes = {
  product: PropTypes.shape({
    id: PropTypes.number.isRequired,
    mainMediaUrl: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    summary: PropTypes.string.isRequired,
    currencySymbol: PropTypes.string.isRequired,
    price: PropTypes.number.isRequired,
  }).isRequired,
};

export default OrderItem;
