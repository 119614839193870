import getStartParamValues from "../utils/getStartParamValues";

const tg = window.Telegram.WebApp;

export default function useTelegram() {
  const onClose = () => {
    tg.close();
  };
  const onToggleMainButton = () => {
    if (!tg.MainButton.isVisible) {
      tg.MainButton.show();
    } else {
      tg.MainButton.hide();
    }
  };
  const onToggleBackButton = () => {
    if (!tg.BackButton.isVisible) {
      tg.BackButton.show();
    } else {
      tg.BackButton.hide();
    }
  };
  const showBackButton = () => {
    if (!tg.BackButton.isVisible) {
      tg.BackButton.show();
    }
  };

  const startParamValues = getStartParamValues(tg?.initDataUnsafe?.start_param);
  return {
    onClose,
    onToggleMainButton,
    onToggleBackButton,
    showBackButton,
    tg,
    webInitDataRaw: tg?.initData,
    user: tg?.initDataUnsafe?.user,
    languageCode: tg?.initDataUnsafe?.user?.language_code,
    queryId: tg?.initDataUnsafe?.query_id,
    authDate: tg?.initDataUnsafe?.auth_date,
    hash: tg?.initDataUnsafe?.hash,
    startParam: tg?.initDataUnsafe?.start_param,
    productId: startParamValues.productId,
    shopId: startParamValues.shopId,
    // cloudStorage: tg?.CloudStorage,language_code
  };
}
