import React from 'react';
import PropTypes from 'prop-types';
// import { Typography } from '@mui/material';
// import DeleteForeverRoundedIcon from '@mui/icons-material/DeleteForeverRounded';
// import { useDispatch } from 'react-redux';
import { noImageURL } from '../../api/config';
import styles from './OrderCardItem.module.css';

function OrderCardItem({ orderItemProduct }) {
//   const dispatch = useDispatch();

  return (
    <div className={styles.cardItem}>
      <img src={orderItemProduct.mainMediaUrl || noImageURL} alt={orderItemProduct.title} />
    </div>
  );
}

OrderCardItem.propTypes = {
  orderItemProduct: PropTypes.shape({
    id: PropTypes.number.isRequired,
    mainMediaUrl: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    // summary: PropTypes.string.isRequired,
    // price: PropTypes.number.isRequired,
    // quantity: PropTypes.number.isRequired,
    // Add other product properties as needed
  }).isRequired,
};

export default OrderCardItem;
