/* eslint no-param-reassign: 0 */
import { createSlice } from "@reduxjs/toolkit";

export const startConfigSlice = createSlice({
  name: "startConfig",
  initialState: {
    isStartParamUsed: false,
    startShopId: null,
  },
  reducers: {
    clear: (state) => {
      state.isStartParamUsed = false;
      state.startShopId = null;
    },
    setStartParamUsing: (state, action) => {
      state.isStartParamUsed = action.payload;
    },
  },
});

export const getStartParamUsing = (state) => state.startConfig.isStartParamUsed;
export const getStartShopid = (state) => state.startConfig.startShopId;

export const { clear, setStartParamUsing } = startConfigSlice.actions;
