/* eslint no-param-reassign: 0 */
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { client } from "../api/client";
import { isAppApiLocal } from "../utils/getAppApiEnv";

export const fetchOrders = createAsyncThunk(
  "orders/fetchOrders",
  async (arg, { getState, extra: { apiClientService }, rejectWithValue }) => {
    try {
      if (isAppApiLocal()) {
        const mockedResponse = await client.get("/mocks/orders.json");
        return mockedResponse.data.data;
      }
      const token = getState().auth.accessToken;
      const response = await apiClientService.fetchOrders(token);
      if (response.status >= 400 && response.status <= 500 && response.status !== 401) {
        return rejectWithValue(response.data);
      }
      return response.data.data;
    } catch (error) {
      return rejectWithValue(error.message || "Failed to fetch orders");
    }
  },
);

export const addOrder = createAsyncThunk(
  "orders/addOrder",
  async (data, { getState, extra: { apiClientService }, rejectWithValue }) => {
    try {
      const token = getState().auth.accessToken;
      const response = await apiClientService.addOrder(data, token);
      if (response.status >= 400 && response.status <= 500 && response.status !== 401) {
        return rejectWithValue(response.data);
      }
      return response.data;
    } catch (error) {
      return rejectWithValue(error.message || "Failed to add order");
    }
  },
);

export const createStripeCheckoutSession = createAsyncThunk(
  "orders/createStripeCheckoutSession",
  async (data, { getState, extra: { apiClientService }, rejectWithValue }) => {
    try {
      const token = getState().auth.accessToken;
      const response = await apiClientService.createStripeCheckoutSession(data, token);
      if (response.status >= 400 && response.status <= 500 && response.status !== 401) {
        return rejectWithValue(response.data);
      }
      return response.data;
    } catch (error) {
      return rejectWithValue(error.message || "Failed to create stripe checkout session");
    }
  },
);

export const ordersSlice = createSlice({
  name: "orders",
  initialState: {
    orders: [],
    status: "idle",
    error: null,
    addingOrderStatus: "idle",
    addingOrderError: null,
    currentOrder: null,
    notifyOrderStatus: "idle",
    stripeClientSecret: null,
    stripeSessionStatus: "idle",
    stripeSessionError: null,
  },
  reducers: {
    load: (state) => {
      state.orders = [];
    },
    clearOrders: (state) => {
      state.orders = [];
      state.status = "idle";
      state.error = null;
    },
    resetAddOrderStatus: (state) => {
      state.addingOrderStatus = "idle";
      state.addingOrderError = null;
    },
    resetNotifyOrderStatus: (state) => {
      state.notifyOrderStatus = "idle";
    },
    resetStripeSession: (state) => {
      state.stripeClientSecret = null;
      state.stripeSessionStatus = "idle";
      state.stripeSessionError = null;
    },
  },
  extraReducers(builder) {
    builder
      .addCase(fetchOrders.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchOrders.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.orders = action.payload || [];
      })
      .addCase(fetchOrders.rejected, (state, action) => {
        state.status = "failed";
        if (action.payload?.errors) {
          state.error = action.payload.errors[0].title;
        } else {
          state.error = action.payload || "An error occurred.";
        }
      })
      .addCase(addOrder.pending, (state) => {
        state.addingOrderStatus = "loading";
        state.notifyOrderStatus = "loading";
      })
      .addCase(addOrder.fulfilled, (state, action) => {
        state.addingOrderStatus = "succeeded";
        state.notifyOrderStatus = "succeeded";
        state.orders.push(action.payload);
        state.currentOrder = action.payload;
      })
      .addCase(addOrder.rejected, (state, action) => {
        state.addingOrderStatus = "failed";
        state.notifyOrderStatus = "failed";
        if (action.payload?.errors) {
          state.error = action.payload.errors[0].title;
        } else {
          state.error = action.payload || "An error occurred.";
        }
      })
      .addCase(createStripeCheckoutSession.pending, (state) => {
        state.stripeSessionStatus = "loading";
      })
      .addCase(createStripeCheckoutSession.fulfilled, (state, action) => {
        state.stripeSessionStatus = "succeeded";
        state.stripeClientSecret = action.payload.clientSecret;
      })
      .addCase(createStripeCheckoutSession.rejected, (state, action) => {
        state.stripeSessionStatus = "failed";
        if (action.payload?.errors) {
          state.error = action.payload.errors[0].title;
        } else {
          state.error = action.payload || "An error occurred.";
        }
      });
  },
});

export const selectOrders = (state) => state.orders.orders || [];
export const getOrdersStatus = (state) => state.orders.status;
// export const selectOrders = (state) => (state.orders ? state.orders.orders || [] : []);
// export const getOrdersStatus = (state) => (state.orders ? state.orders.status : null);
export const getAddingOrderStatus = (state) => state.orders.addingOrderStatus;
export const getAddingOrderError = (state) => state.orders.addingOrderError;
export const getNotifyOrderStatus = (state) => state.orders.notifyOrderStatus;
export const getStripeClientSecret = (state) => state.orders.stripeClientSecret;
export const getStripeSessionStatus = (state) => state.orders.stripeSessionStatus;
export const getStripeSessionError = (state) => state.orders.stripeSessionError;
export const getCurrentOrder = (state) => state.orders.currentOrder;
export const getOrdersError = (state) => state.orders.error;

export const {
  load,
  clearOrders,
  resetAddOrderStatus,
  resetNotifyOrderStatus,
  resetStripeSession,
} = ordersSlice.actions;

export default ordersSlice.reducer;
