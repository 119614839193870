// const isValidBase64 = (str) => {
//   try {
//     return btoa(atob(str)) === str;
//   } catch (err) {
//     // eslint-disable-next-line no-console
//     console.log('invalid base64  string');
//     return false;
//   }
// };

const getStartParamValues = (startParam) => {
  if (startParam === undefined || startParam === null) {
    return { shopId: null, productId: null };
  }
  const [shopId, productId] = startParam.split('_')
    .map((part) => (part ? parseInt(part, 10) : null));
  return { shopId, productId };

  // if (startParam === undefined || startParam === null || !isValidBase64(startParam)) {
  //   return { shopId: null, productId: null };
  // }
  // const decodedString = atob(startParam);
  // const [shopId, productId] = decodedString.split('-')
  //   .map((part) => (part ? parseInt(part, 10) : null));
  // return { shopId, productId };
};

export default getStartParamValues;
