import useApiClient from "../hooks/useApiClient";

const createApiClientService = () => {
  const apiClient = useApiClient();

  return {
    authenticateUser: async (data) => apiClient.post("/auth", data),
    createStripeCheckoutSession: async (data, token) => apiClient.post("/stripe/create-payment-intent", data, token),
    fetchOrderById: async (id, token) => apiClient.get(`/checkout/orders/${id}`, token),
    fetchOrders: async (token) => apiClient.get("/checkout/orders", token),
    addOrder: async (data, token) => apiClient.post("/checkout/orders", data, token),
    fetchOrderItemStatuses: async (orderId, itemId, token) => apiClient.get(`/checkout/orders/${orderId}/items/${itemId}/statuses`, token),
    addOrderItemStatus: async (orderId, itemId, statusId, token) => apiClient.post(`/checkout/orders/${orderId}/items/${itemId}/statuses/${statusId}`, null, token),
    fetchCountries: async (token) => apiClient.get("/countries", token),
    fetchAvailablePaymentMethods: async (token) => apiClient.get("/checkout/available-payment-methods", token),
    fetchProductById: async (id, token) => apiClient.get(`/products/${id}`, token),
    fetchProducts: async (shopId, token) => {
      const url = shopId ? `/products?shopId=${shopId}` : "/products";
      return apiClient.get(url, token);
    },
    fetchShippingInfos: async (token) => apiClient.get("/shippings", token),
    addNewShippingInfo: async (data, token) => apiClient.post("/shippings", data, token),
    updateShippingInfo: async (data, token) => apiClient.put("/shippings", data, token),
    switchDefaultShippingInfo: async (shippingId, token) => apiClient.post(`/shippings/default/${shippingId}`, null, token),
  };
};

export default createApiClientService;
