import React from 'react';
import {
  List,
  ListItem,
  ListItemText,
  ListItemIcon,
  Radio,
  IconButton,
  Button,
  Typography,
} from '@mui/material';
import PropTypes from 'prop-types';
import EditIcon from '@mui/icons-material/EditRounded';
import AddIcon from '@mui/icons-material/AddRounded';

function ShippingList({
  shippingInfos,
  selectedAddress,
  handleAddressSelect,
  handleAddressEdit,
  handleAddNewAddress,
  handleSaveDefaultAddress,
}) {
  return (
    <div>
      <List>
        {shippingInfos.map((shipping) => (
          <ListItem
            key={shipping.id}
            onClick={() => handleAddressSelect(shipping)}
            style={{ paddingRight: '0px', color: 'var(--tg-theme-text-color)', cursor: 'pointer' }}
          >
            <ListItemIcon>
              <Radio
                checked={selectedAddress?.id === shipping.id}
                value="a"
                name="radio-buttons"
                inputProps={{ 'aria-label': 'A' }}
                sx={{ color: 'var(--tg-theme-hint-color)' }}
              />
            </ListItemIcon>
            <ListItemText primary={`${shipping.address1} ${shipping.firstName} ${shipping.lastName}`} />
            <IconButton onClick={() => handleAddressEdit(shipping)}>
              <EditIcon style={{ color: 'var(--tg-theme-link-color)' }} />
            </IconButton>
          </ListItem>
        ))}
      </List>
      <div style={{ padding: '0px 0px 16px 16px', display: 'flex', alignItems: 'center' }}>
        <IconButton onClick={handleAddNewAddress}>
          <AddIcon style={{ color: 'var(--tg-theme-hint-color)' }} />
          <Typography variant="body1" style={{ marginLeft: '8px', cursor: 'pointer', color: 'var(--tg-theme-text-color)' }}>
            New address
          </Typography>
        </IconButton>
      </div>
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
        }}
      >
        <Button
          variant="contained"
          // color="primary"
          // color="var(--tg-theme-button-color)"
          onClick={handleSaveDefaultAddress}
          disableElevation
          disabled={!selectedAddress}
          sx={{
            borderRadius: '25px',
            // backgroundColor: '#fbe304',
            backgroundColor: 'var(--tg-theme-button-color)',
            // color: '#333',
            color: 'var(--tg-theme-button-text-color)',
            margin: '10px',
            minHeight: '50px',
          }}
        >
          Save Address
        </Button>
      </div>
    </div>
  );
}

ShippingList.propTypes = {
  shippingInfos: PropTypes.func.isRequired,
  selectedAddress: PropTypes.bool.isRequired,
  handleAddressSelect: PropTypes.bool.isRequired,
  handleAddressEdit: PropTypes.bool.isRequired,
  handleAddNewAddress: PropTypes.bool.isRequired,
  handleSaveDefaultAddress: PropTypes.bool.isRequired,
};

export default ShippingList;
