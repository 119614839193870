import React from 'react';
import {
  AppBar,
  Toolbar,
  IconButton,
  Typography,
  Badge,
  Skeleton as MuiSkeleton,
} from '@mui/material';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIosRounded';
import ShoppingCartRoundedIcon from '@mui/icons-material/ShoppingCartRounded';
import { useSelector } from 'react-redux';
import { getTotalCartItemCount } from '../../redux/cartSlice';
import styles from './ProductDetails.module.css';

function ProductDetailsSkeleton() {
  const totalCartItemCount = useSelector(getTotalCartItemCount);

  return (
    <div className={styles.productDetailsContainer}>
      <AppBar
        position="fixed"
        sx={{ backgroundColor: 'var(--tg-theme-header-bg-color)', boxShadow: 'none', maxHeight: '56px' }}
      >
        <Toolbar sx={{ height: '56px' }}>
          <IconButton>
            <ArrowBackIosIcon className={styles.headerIcon} />
          </IconButton>
          <Typography variant="body1" className={styles.headerText}>
            <MuiSkeleton variant="text" width={120} />
          </Typography>
          <div style={{ flexGrow: 1 }} />
          <IconButton>
            <Badge badgeContent={totalCartItemCount} color="error">
              <ShoppingCartRoundedIcon className={styles.headerIcon} />
            </Badge>
          </IconButton>
        </Toolbar>
      </AppBar>
      <div className={styles.productDetails}>
        <div className={styles.productImageContainer}>
          <MuiSkeleton variant="rectangular" width="80%" height={300} />
        </div>
        <Typography variant="h5" className={styles.productName}>
          <MuiSkeleton variant="text" width="40%" />
        </Typography>
        <Typography variant="body1" className={styles.productDescription}>
          <MuiSkeleton variant="text" width="60%" />
          <div style={{ height: 32 }} />
          {/* Empty space */}
          <MuiSkeleton variant="h5" width="40%" />
          <div style={{ height: 16 }} />
          {/* Empty space */}
          <MuiSkeleton variant="text" width="80%" />
          <MuiSkeleton variant="text" width="70%" />
          <div style={{ height: 32 }} />
          {/* Empty space */}
          <MuiSkeleton variant="h5" width="40%" />
          <div style={{ height: 16 }} />
          {/* Empty space */}
          <MuiSkeleton variant="text" width="70%" />
          <MuiSkeleton variant="text" width="70%" />
          <div style={{ height: 32 }} />
          {/* Empty space */}
        </Typography>
      </div>
    </div>
  );
}

export default ProductDetailsSkeleton;
