import React from "react";
import PropTypes from "prop-types";
import { Typography, Paper, List, Divider } from "@mui/material";
import styles from "./Characteristics.module.css";

function Characteristics({ product }) {
  // Check if product.attributes is not empty
  if (!product.attributes || Object.keys(product.attributes).length === 0) {
    return null; // Return nothing if there are no attributes
  }

  return (
    <Paper
      className={styles.paper}
      sx={{ backgroundColor: "var(--tg-theme-section-bg-color)" }}
    >
      <Typography
        variant="h5"
        sx={{ color: "var(--tg-theme-section-header-text-color)" }}
      >
        Characteristics
      </Typography>
      <List sx={{ width: "100%" }}>
        {Object.keys(product.attributes).map((key, index) => (
          <div key={key}>
            <div className={styles.attributesPair}>
              <Typography
                variant="subtitle1"
                className={styles.nonEditableText}
                sx={{
                  marginRight: "10px",
                  color: "var(--tg-theme-text-color)",
                  wordBreak: "break-word",
                  width: "40%",
                }}
              >
                {key.charAt(0).toUpperCase() + key.slice(1)}
              </Typography>
              <Typography
                variant="subtitle1"
                className={styles.editableText}
                sx={{
                  color: "var(--tg-theme-text-color)",
                  wordBreak: "break-word",
                  width: "60%",
                  textAlign: "right",
                }}
              >
                {product.attributes[key]}
              </Typography>
            </div>
            {index + 1 < Object.keys(product.attributes).length && <Divider />}
          </div>
        ))}
      </List>
    </Paper>
  );
}

Characteristics.propTypes = {
  product: PropTypes.shape({
    attributes: PropTypes,
  }).isRequired,
};

export default Characteristics;
