import "./App.css";
import React, { useEffect } from "react";
import { Route, Routes, BrowserRouter } from "react-router-dom";
import { THEME, TonConnectUIProvider } from "@tonconnect/ui-react";
import { useDispatch, useSelector } from "react-redux";
import Footer from "./components/Footer/Footer";
import ProductList from "./components/Products/ProductList";
import ProductDetails from "./components/Products/ProductDetails";
import ShoppingCart from "./components/Cart/Cart";
import Checkout from "./components/Checkout/Order";
import OrderList from "./components/History/OrderList";
import Greeting from "./components/Home/Greeting";
import OrderDetails from "./components/History/OrderDetails";
import StripeCheckout from "./components/Checkout/StripeCheckout";
import useTelegram from "./hooks/useTelegram";
import { authenticateUser, getAccessToken } from "./redux/authSlice";
import { isAppApiLocal } from "./utils/getAppApiEnv";
import ErrorAlert from "./components/Home/ErrorAlert";
import RedirectToProducts from "./components/Redirect/RedirectToProducts";

function App() {
  const dispatch = useDispatch();
  const { tg, webInitDataRaw, user, queryId, authDate, hash, startParam }
    = useTelegram();

  const accessToken = useSelector(getAccessToken);

  useEffect(() => {
    if (!isAppApiLocal()) {
      tg.expand();
      tg.ready();
      tg.enableClosingConfirmation();
      tg.disableVerticalSwipes();
    }
  }, [tg, startParam]);

  useEffect(() => {
    if (user && !isAppApiLocal()) {
      const authenticate = async () => {
        const initData = {
          queryId,
          webInitDataRaw,
          user,
          authDate,
          hash,
        };
        dispatch(authenticateUser(initData));
      };

      authenticate();
    }
  }, [dispatch, authDate, hash, queryId, user, webInitDataRaw]);

  if (!accessToken && !isAppApiLocal()) {
    return <Greeting />;
  }

  return (
    <TonConnectUIProvider
      manifestUrl="https://raw.githubusercontent.com/ton-community/tutorials/main/03-client/test/public/tonconnect-manifest.json"
      uiPreferences={{
        theme: THEME.LIGHT,
        colorsSet: {
          [THEME.LIGHT]: {
            connectButton: {
              background: "var(--tg-theme-button-color)",
            },
          },
        },
      }}
      actionsConfiguration={{
        twaReturnUrl: "https://t.me/aoki_market_bot/aoki",
      }}
    >
      <div className="App">
        <BrowserRouter>
          <ErrorAlert />
          <Routes>
            <Route path="/" element={<RedirectToProducts />} />
            <Route path="/products" element={<ProductList />} />
            <Route path="/products/:id" element={<ProductDetails />} />
            <Route path="/cart" element={<ShoppingCart />} />
            <Route path="/checkout" element={<Checkout />} />
            <Route
              path="/checkout/stripe-checkout"
              element={<StripeCheckout />}
            />
            <Route path="/orders" element={<OrderList />} />
            <Route path="/orders/:id" element={<OrderDetails />} />
          </Routes>
        </BrowserRouter>
        <Footer />
      </div>
    </TonConnectUIProvider>
  );
}

export default App;
