import React from 'react';
import {
  Skeleton as MuiSkeleton,
  AppBar,
  Toolbar,
  IconButton,
  Badge,
} from '@mui/material';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIosRounded';
import ShoppingCartRoundedIcon from '@mui/icons-material/ShoppingCartRounded';
import { useSelector } from 'react-redux';
import { getTotalCartItemCount } from '../../redux/cartSlice';
import styles from './Skeleton.module.css';

function Skeleton() {
  const totalCartItemCount = useSelector(getTotalCartItemCount);

  return (
    <div className={styles.skeletonContainer}>
      <AppBar
        position="fixed"
        sx={{
          backgroundColor: 'var(--tg-theme-header-bg-color)',
          boxShadow: 'none',
          height: '56px',
          // opacity: '0.85',
          borderRadius: '0 0 26px 26px',
        }}
      >
        <Toolbar sx={{ height: '56px' }}>
          <IconButton>
            <ArrowBackIosIcon className={styles.headerIcon} />
          </IconButton>
          <div style={{ flexGrow: 1 }} />
          {/* Add flexGrow to push next icon to the right */}
          <IconButton>
            <Badge badgeContent={totalCartItemCount} color="error">
              <ShoppingCartRoundedIcon className={styles.headerIcon} />
            </Badge>
          </IconButton>
        </Toolbar>
      </AppBar>
      <div className={styles.skeletonList}>
        {[1, 2, 3].map((index) => (
          <div key={index} className={styles.skeletonCard}>
            <div className={styles.textItem}>
              <MuiSkeleton variant="text" width={150} sx={{ fontSize: '1.4rem', padding: '10px' }} />
            </div>
            <div className={styles.cardItems}>
              {[1, 2].map(() => (
                <MuiSkeleton variant="rounded" width={100} height={100} style={{ marginBottom: '20px' }} />
              ))}
            </div>
            <div className={styles.textItem}>
              <MuiSkeleton variant="text" width={50} sx={{ fontSize: '1.2rem' }} />
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}

export default Skeleton;
