import React from 'react';
import PropTypes from 'prop-types';
import {
  Typography,
} from '@mui/material';
import styles from './ShippingForm.module.css';

function Label({ label }) {
  return (
    <Typography
      variant="subtitle1"
      className={styles.tittleAddressText}
      sx={{ marginRight: '10px', minWidth: '90px' }}
    >
      {`${label}`}
    </Typography>
  );
}

Label.propTypes = {
  label: PropTypes.string.isRequired,
};

export default Label;
