import React, {
  useCallback,
  useEffect,
  useState,
} from 'react';
import {
  SwipeableDrawer,
  Typography,
  Box,
} from '@mui/material';
import PropTypes from 'prop-types';
// import { grey } from '@mui/material/colors';
import { styled } from '@mui/material/styles';
import HighlightOffRoundedIcon from '@mui/icons-material/HighlightOffRounded';
import { useSelector, useDispatch } from 'react-redux';
import {
  fetchShippingInfos,
  selectAllShippingInfos,
  getshippingInfoStatus,
  switchDefaultShippingInfo,
  selectDefaultShipping,
} from '../../redux/shippingInfoSlice';
import { fetchCountries, getCountriesStatus } from '../../redux/countriesSlice';
import ShippingList from './ShippingList';
import ShippingForm from './ShippingForm';

// const Puller = styled(Box)(({ theme }) => ({
const Puller = styled(Box)(() => ({
  width: 40,
  height: 6,
  // backgroundColor: theme.palette.mode === 'light' ? grey[300] : grey[900],
  backgroundColor: 'var(--tg-theme-hint-color)',
  borderRadius: 3,
  position: 'absolute',
  top: 8,
  left: 'calc(50% - 20px)',
}));

// const StyledCloseIcon = styled(HighlightOffRoundedIcon)(({ theme }) => ({
//   color: theme.palette.mode === 'light' ? grey[300] : grey[900],
//   '&:hover': {
//     color: theme.palette.mode === 'light' ? grey[800] : grey[400],
//   },
// }));

const StyledCloseIcon = styled(HighlightOffRoundedIcon)(() => ({
  color: 'var(--tg-theme-hint-color)',
  '&:hover': {
    color: 'var(--tg-theme-section-header-text-color)',
  },
}));

function Shipping({
  toggleDrawer, drawerOpen,
}) {
  const shippingInfos = useSelector(selectAllShippingInfos);
  const defaultShipping = useSelector(selectDefaultShipping);
  const shippingInfoStatus = useSelector(getshippingInfoStatus);
  const countriesStatus = useSelector(getCountriesStatus);
  const [selectedAddress, setSelectedAddress] = useState(defaultShipping);
  const dispatch = useDispatch();
  // const [showEditForm, setShowEditForm] = useState(false);
  const [editableShipping, setEditableShipping] = useState(null);
  const [addingNewAddress, setAddingNewAddress] = useState(false);

  const handleAddressEdit = (shipping) => {
    // Handle the edit action, then show the edit form.
    // You might want to set the shipping data in the component's state.
    setEditableShipping(shipping);
  };

  const handleCommonExit = () => {
    // Reset the data and perform any necessary cleanup
    setSelectedAddress(defaultShipping); // Reset the selected address to the default
    setEditableShipping(null); // Clear any editable shipping data
    setAddingNewAddress(false); // Close the new address form, if open
    // You can perform additional actions here if needed
  };

  const handleClose = () => {
    handleCommonExit();
    toggleDrawer(false)();
  };

  useEffect(() => {
    if (shippingInfoStatus === 'idle') {
      dispatch(fetchShippingInfos());
    }
  }, [dispatch, shippingInfoStatus]);

  useEffect(() => {
    if (!selectedAddress && defaultShipping !== selectedAddress) {
      setSelectedAddress(defaultShipping);
    }
  }, [defaultShipping, selectedAddress]);

  useEffect(() => {
    if (countriesStatus === 'idle' && drawerOpen) {
      dispatch(fetchCountries());
    }
  }, [dispatch, countriesStatus, drawerOpen]);

  const handleSaveDefaultAddress = useCallback((event) => {
    toggleDrawer(false)(event);
    dispatch(switchDefaultShippingInfo(selectedAddress.id));
  }, [toggleDrawer, dispatch, selectedAddress]);

  const handleAddressSelect = useCallback((address) => {
    setSelectedAddress(address);
  }, [setSelectedAddress]);

  const handleAddNewAddress = () => {
    setAddingNewAddress(true);
  };

  const handleSaveNewAddress = useCallback(async () => {
    handleClose();
    setAddingNewAddress(false);
    dispatch(fetchShippingInfos());
    setSelectedAddress(null);
  }, [dispatch]);

  const handleSaveShippingInfo = useCallback(async () => {
    handleClose();
    setEditableShipping(null);
    dispatch(fetchShippingInfos());
    setSelectedAddress(null);
  }, [dispatch]);

  let contentToRender;
  if (addingNewAddress) {
    contentToRender = (
      <ShippingForm onSave={handleSaveNewAddress} />
    );
  } else if (editableShipping) {
    contentToRender = (
      <ShippingForm
        initialValues={editableShipping}
        onSave={handleSaveShippingInfo}
      />
    );
  } else {
    contentToRender = (
      <ShippingList
        shippingInfos={shippingInfos}
        selectedAddress={selectedAddress}
        handleAddressSelect={handleAddressSelect}
        handleAddressEdit={handleAddressEdit}
        handleAddNewAddress={handleAddNewAddress}
        handleSaveDefaultAddress={handleSaveDefaultAddress}
      />
    );
  }

  return (
    <SwipeableDrawer
      anchor="bottom"
      open={drawerOpen}
      onClose={handleClose}
      onOpen={toggleDrawer(true)}
      PaperProps={{
        sx: {
          borderTopLeftRadius: 26,
          borderTopRightRadius: 26,
          backgroundColor: 'var(--tg-theme-secondary-bg-color)',
        },
      }}
    >
      <Puller />
      <StyledCloseIcon
        onClick={handleClose}
        style={{
          position: 'absolute',
          top: '20px',
          right: '20px',
        }}
      />
      <div
        style={{
          padding: '12px',
          display: 'flex',
          flexDirection: 'column',
        }}
      >
        <Typography variant="h6" fontWeight="bold" sx={{ margin: '4px 0px 0px 12px', color: 'var(--tg-theme-section-header-text-color)' }}>Delivery Address</Typography>
        {contentToRender}
      </div>
    </SwipeableDrawer>
  );
}

Shipping.propTypes = {
  toggleDrawer: PropTypes.func.isRequired,
  drawerOpen: PropTypes.bool.isRequired,
};

export default Shipping;
