import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import {
  AppBar,
  Typography,
  Toolbar,
  IconButton,
  Badge,
} from "@mui/material";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIosRounded";
import ShoppingCartRoundedIcon from "@mui/icons-material/ShoppingCartRounded";
import { useSelector, useDispatch } from "react-redux";
import {
  fetchOrders,
  selectOrders,
  getOrdersStatus,
  getNotifyOrderStatus,
  resetNotifyOrderStatus,
} from "../../redux/ordersSlice";
import { getTotalCartItemCount } from "../../redux/cartSlice";
import OrderCard from "./OrderCard";
import Skeleton from "./Skeleton";
import styles from "./OrderList.module.css";

function OrderList() {
  const orders = useSelector(selectOrders);
  const ordersStatus = useSelector(getOrdersStatus);
  const totalCartItemCount = useSelector(getTotalCartItemCount);
  const notifyOrderStatus = useSelector(getNotifyOrderStatus);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  useEffect(() => {
    if (ordersStatus === "idle") {
      dispatch(fetchOrders());
    }
  }, [ordersStatus, dispatch]);

  useEffect(() => {
    if (notifyOrderStatus === "succeeded") {
      dispatch(resetNotifyOrderStatus());
    }
  }, [notifyOrderStatus, dispatch]);

  const navigateToProducts = () => {
    navigate("/products");
  };
  const navigateToCart = () => {
    navigate("/cart");
  };

  if (ordersStatus === "loading") {
    return <Skeleton />;
  }

  return (
    <div className={styles.orderListContainer}>
      <AppBar
        position="fixed"
        sx={{
          // backgroundColor: "var(--tg-theme-section-bg-color)",
          // boxShadow: "none",
          backgroundColor: "transparent",
          boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.2)",
          height: "56px",
          backdropFilter: "blur(42px)",
          WebkitBackdropFilter: "blur(42px)",
          borderRadius: "0 0 26px 26px",
        }}
      >
        <Toolbar sx={{ height: "56px" }}>
          <IconButton onClick={navigateToProducts}>
            <ArrowBackIosIcon className={styles.headerIcon} />
          </IconButton>
          <div style={{ flexGrow: 1 }} />
          {/* Add flexGrow to push next icon to the right */}
          <IconButton onClick={navigateToCart}>
            <Badge badgeContent={totalCartItemCount} color="error">
              <ShoppingCartRoundedIcon className={styles.headerIcon} />
            </Badge>
          </IconButton>
        </Toolbar>
      </AppBar>
      {/* <Typography
        variant="h5"
        sx={{ margin: "10px", color: "var(--tg-theme-section-header-text-color)" }}
      >
        Orders History
      </Typography> */}
      <div className={styles.orderList}>
        {orders && orders.length > 0 ? (orders.map((order) => (
          <OrderCard key={order.id} order={order} />
        ))) : (
          <Typography
            variant="h6"
            sx={{ margin: "0px", padding: "150px 0px", color: "var(--tg-theme-destructive-text-color)" }}
          >
            Orders not found
          </Typography>
        )}
      </div>
    </div>
  );
}

export default OrderList;
