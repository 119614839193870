import React, { useState } from "react";
// import { useSelector } from 'react-redux';
import { Typography, IconButton } from "@mui/material";
import ContentCopyRoundedIcon from "@mui/icons-material/ContentCopyRounded";
import styles from "./Footer.module.css";
import useTelegram from "../../hooks/useTelegram";
// import { getAccessToken } from '../../redux/authSlice';

function Footer() {
  const addressToCopy = "bc1qfwgdm55706g9l5f9mcewwpa4nqn22vvpd5d7zx";
  const [isClicked, setIsClicked] = useState(false);
  // const [token, setToken] = useState('');
  const { user } = useTelegram();
  // const accessToken = useSelector(getAccessToken);
  const username = `${user?.first_name} ${user?.last_name}`;

  const handleCopyClick = async () => {
    try {
      setIsClicked(true);
      await navigator.clipboard.writeText(addressToCopy);
      // alert('Address copied to clipboard');
    } catch (err) {
      // console.error('Failed to copy address: ', err);
    }
  };

  return (
    <div className={styles.appFooter}>
      <Typography variant="body1" className={styles.footerTitle}>
        {username}
      </Typography>
      <div className={styles.footerTitle}>
        <Typography variant="body2">Do not send bitcoin here</Typography>
        <IconButton onClick={handleCopyClick}>
          <ContentCopyRoundedIcon
            style={{
              cursor: "pointer",
              // color: isClicked ? '#333' : '#ababab',
              color: isClicked
                ? "var(--tg-theme-subtitle-text-color)"
                : "var(--tg-theme-link-color)",
              fontSize: "1rem",
              zIndex: "1",
            }}
          />
        </IconButton>
      </div>
      <Typography variant="body1" className={styles.footerTitle}>
        &copy; 2024 AOKI
      </Typography>
    </div>
  );
}

export default Footer;
