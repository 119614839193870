import React from "react";
import { lableURL } from "../../api/config";
import styles from "./Greeting.module.css";

function Greeting() {
  return (
    <div className={styles.greetingContainer}>
      <img className={styles.greetingImg} src={lableURL} alt="" />
    </div>
  );
}

export default Greeting;
