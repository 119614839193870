const getFormattedDate = (dateString) => {
  const date = new Date(dateString);
  const year = date.getFullYear();
  const month = date.toLocaleString('default', { month: 'long' });
  const day = date.getDate();

  const formattedDate = `${day} ${month} ${year}`;
  return formattedDate;
};

export default getFormattedDate;
