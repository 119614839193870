/* eslint no-param-reassign: 0 */
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

export const fetchPaymentMethods = createAsyncThunk(
  "paymentMethods/fetchPaymentMethods",
  async (arg, { getState, extra: { apiClientService }, rejectWithValue }) => {
    try {
      const token = getState().auth.accessToken;
      const response = await apiClientService.fetchAvailablePaymentMethods(token);
      if (response.status >= 400 && response.status <= 500 && response.status !== 401) {
        return rejectWithValue(response.data);
      }
      return response.data.data;
    } catch (error) {
      return rejectWithValue(error.message || "Failed to fetch payment methods");
    }
  },
);

export const paymentMethodsSlice = createSlice({
  name: "paymentMethods",
  initialState: {
    availablePaymentMethods: [],
    status: "idle",
    selectedMethod: null,
    error: null,
  },
  reducers: {
    load: (state) => {
      state.availablePaymentMethods = [];
    },
    setSelectedMethod: (state, action) => {
      state.selectedMethod = action.payload;
    },
    clearSelectedMethod: (state) => {
      state.selectedMethod = null;
      state.status = "idle";
      state.error = null;
    },
  },
  extraReducers(builder) {
    builder
      .addCase(fetchPaymentMethods.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchPaymentMethods.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.availablePaymentMethods = action.payload || [];
      })
      .addCase(fetchPaymentMethods.rejected, (state, action) => {
        state.status = "failed";
        if (action.payload?.errors) {
          state.error = action.payload.errors[0].title;
        } else {
          state.error = action.payload || "An error occurred.";
        }
      });
  },
});

export const getPaymentMethods = (state) => state.paymentMethods.availablePaymentMethods || [];
export const getPaymentMethodsStatus = (state) => state.paymentMethods.status;
export const selectedPaymentMethod = (state) => state.paymentMethods.selectedMethod;
export const getPaymentMethodsError = (state) => state.paymentMethods.error;

export const { load, setSelectedMethod, clearSelectedMethod } = paymentMethodsSlice.actions;
