/* eslint no-param-reassign: 0 */
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

export const authenticateUser = createAsyncThunk(
  "auth/authenticateUser",
  async (request, { extra: { apiClientService }, rejectWithValue }) => {
    try {
      const response = await apiClientService.authenticateUser(request);
      if (response.status >= 400 && response.status <= 500) {
        return rejectWithValue(response.data);
      }
      return response.data;
    } catch (error) {
      return rejectWithValue(error.message || "Failed to authenticate");
    }
  },
);

export const authSlice = createSlice({
  name: "auth",
  initialState: {
    accessToken: null,
    // refreshToken: null,
    status: "idle",
    error: null,
  },
  reducers: {
    load: (state) => {
      state.accessToken = null;
    },
  },
  extraReducers(builder) {
    builder
      .addCase(authenticateUser.pending, (state) => {
        state.status = "loading";
      })
      .addCase(authenticateUser.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.accessToken = action.payload.accessToken;
        // state.refreshToken = action.payload.refreshToken;
      })
      .addCase(authenticateUser.rejected, (state, action) => {
        state.status = "failed";
        if (action.payload?.errors) {
          state.error = action.payload.errors[0].title;
        } else {
          state.error = action.payload || "An error occurred.";
        }
      });
  },
});

export const getAccessToken = (state) => state.auth.accessToken;
// export const getRefreshToken = (state) => state.auth.refreshToken;
export const getAuthStatus = (state) => state.auth.status;
export const getAuthError = (state) => state.auth.error;
