import { baseURL } from "../api/config";
import useTelegram from "./useTelegram";

const useApiClient = () => {
  const { languageCode } = useTelegram();

  const getAuthorizationHeader = (token) => ({
    Authorization: `Bearer ${token}`,
  });

  // Main apiClient function for making API requests
  const apiClient = async (endpoint, { body, method, token, ...customConfig } = {}) => {
    // Base headers
    const headers = {
      "Content-Type": "application/json",
      "Accept-Language": languageCode,
      ...(token && getAuthorizationHeader(token)),
    };

    // Configure the request with method, headers, and body for the fetch request
    const config = {
      ...customConfig,
      method,
      headers: {
        ...headers,
        ...customConfig.headers,
      },
    };

    if (body) {
      config.body = JSON.stringify(body);
    }

    try {
      // Perform the fetch request
      const response = await window.fetch(`${baseURL}${endpoint}`, config);
      // Check if the response has a body
      const hasBody = response.headers.get("content-length") !== "0";
      const data = hasBody ? await response.json() : null;

      if (!response.ok) {
        return {
          status: response.status,
          data: data || null,
        };
      }

      if (response.ok) {
        // Return response object similar to Axios
        return {
          status: response.status,
          data,
          headers: response.headers,
          url: response.url,
        };
      }
      // Throw an error if response is not ok
      throw new Error(`Request failed with status: ${response.statusText}`);
    } catch (error) {
      // Propagate the error
      return Promise.reject(error.message ? error.message : error);
    }
  };

  // Add GET, POST, PUT methods using client.js
  apiClient.get = function (endpoint, token, customConfig = {}) {
    return apiClient(endpoint, {
      ...customConfig,
      method: "GET",
      token,
    });
  };

  apiClient.post = function (endpoint, body, token, customConfig = {}) {
    return apiClient(endpoint, {
      ...customConfig,
      body,
      method: "POST",
      token,
    });
  };

  apiClient.put = function (endpoint, body, token, customConfig = {}) {
    return apiClient(endpoint, {
      ...customConfig,
      body,
      method: "PUT",
      token,
    });
  };

  return apiClient;
};

export default useApiClient;
